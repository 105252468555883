import React from "react";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import {
  FacebookLoginButton,
  GoogleLoginButton,
} from "react-social-login-buttons";
import { useLocation, useNavigate } from "react-router-dom";
import { GlobalContext, IGlobalContext, IUser } from "../GlobalContextProvider";
import {
  q,
  isValidEmail,
  isValidUsername,
  isValidPassword,
  IValidationResult,
} from "../Tools";

const lfCfg = require("../lf-config-frontend.json");

enum ErrorType {
  Login,
  SignUpEmail,
  SignUpPassword,
  SignUpRepeatPassword,
  SignUpRequest,
}

enum LoginStatus {
  ShowLoginAndSignUp,
  SignUpCompleted,
}

interface IError {
  type: ErrorType;
  message?: string;
}

function Login() {
  const globalContext: IGlobalContext = React.useContext(GlobalContext);
  const [error, setError] = React.useState<IError | null>(null);
  const [loginEmail, setLoginEmail] = React.useState<string>("");
  const [loginPassword, setLoginPassword] = React.useState<string>("");

  const [signUpEmail, setSignUpEmail] = React.useState<string>("");
  const [signUpPassword, setSignUpPassword] = React.useState<string>("");
  const [signUpRepeatPassword, setSignUpRepeatPassword] =
    React.useState<string>("");

  const [status, setStatus] = React.useState<LoginStatus>(
    LoginStatus.ShowLoginAndSignUp
  );

  const navigate = useNavigate();

  const login = async () => {
    q(`logging in ...`);
    try {
      const result = await fetch(lfCfg.backend + "/_login", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: loginEmail,
          password: loginPassword,
        }),
      });

      const data = await result.json();
      if (data.errorId === 0) {
        q(`logged in: ${JSON.stringify(data)}`);
        // if user has not username, bring him to /_setup
        globalContext.dispatch({
          type: "SET_USER",
          value: data.user as IUser,
        });

        if (data.user.username === null) {
          navigate("/_setup");
        } else {
          navigate("/" + data.user.username);
        }
      } else {
        globalContext.dispatch({
          type: "SET_USER",
          value: null,
        });

        setError({ type: ErrorType.Login, message: data.message });
      }
    } catch (e) {
      q(`error signing up: ${e}`);
      setError({ type: ErrorType.Login, message: JSON.stringify(e) });
    }
  };

  const signUp = async () => {
    q(`signing up ...`);
    const emailValidationResult: IValidationResult = isValidEmail(signUpEmail);
    if (emailValidationResult.errorId !== 0) {
      setError({
        type: ErrorType.SignUpEmail,
        message: emailValidationResult.message,
      });
      return;
    }

    const passwordValidationResult: IValidationResult =
      isValidPassword(signUpPassword);
    if (passwordValidationResult.errorId !== 0) {
      setError({
        type: ErrorType.SignUpPassword,
        message: passwordValidationResult.message,
      });
      return;
    }

    if (signUpPassword !== signUpRepeatPassword) {
      setError({
        type: ErrorType.SignUpRepeatPassword,
        message: "Passwords need to be identical",
      });
      return;
    }

    try {
      const result = await fetch(lfCfg.backend + "/_signup", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: signUpEmail,
          password: signUpPassword,
        }),
      });

      const data = await result.json();
      if (data.errorId === 0) {
        q(`signed up: ${JSON.stringify(data)}`);
        setStatus(LoginStatus.SignUpCompleted);
      } else {
        setError({
          type: ErrorType.SignUpRequest,
          message: data.message,
        });
      }
    } catch (e) {
      setError({
        type: ErrorType.SignUpRequest,
        message: e as string,
      });
    }
  };

  const loginFacebook = () => {
    const url = lfCfg.backend + "/_loginfb";
    window.location.href = url;
  };

  const loginGoogle = () => {
    const url = lfCfg.backend + "/_logingoogle";
    window.location.href = url;
  };

  return (
    <Box
      sx={{
        display: "flex",
        marginTop: 12,
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {status === LoginStatus.ShowLoginAndSignUp && (
        <>
          <Paper
            elevation={3}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: 4,
            }}
          >
            <Typography variant="h5">Login</Typography>
            <TextField
              sx={{ marginTop: 2 }}
              id="outlined-basic"
              label="Email"
              variant="outlined"
              value={loginEmail}
              onChange={(e) => {
                setError(null);
                setLoginEmail(e.target.value);
              }}
            />
            <TextField
              sx={{ marginTop: 2 }}
              id="outlined-basic"
              label="Password"
              variant="outlined"
              type="password"
              value={loginPassword}
              onChange={(e) => {
                setError(null);
                setLoginPassword(e.target.value);
              }}
            />
            <Typography variant="body2" color="red" sx={{ height: 20 }}>
              {error && error.type === ErrorType.Login && error.message}
            </Typography>
            <Button sx={{ marginTop: 2 }} onClick={login} variant="contained">
              Login
            </Button>
            <Link sx={{ marginTop: 2 }} href="/_forgotpassword">
              Forgot Password?
            </Link>
          </Paper>
          <Paper
            elevation={3}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: 4,
              marginTop: 4,
            }}
          >
            <Typography variant="h5">No account yet? Sign up!</Typography>
            <TextField
              sx={{ marginTop: 2 }}
              id="outlined-basic"
              label="Email"
              variant="outlined"
              helperText={
                error && error.type === ErrorType.SignUpEmail ? (
                  <Typography variant="body2" color="red">
                    {error.message}
                  </Typography>
                ) : null
              }
              value={signUpEmail}
              onChange={(e) => {
                setError(null);
                setSignUpEmail(e.target.value);
              }}
            />
            <TextField
              sx={{ marginTop: 2 }}
              id="outlined-basic"
              label="Password"
              variant="outlined"
              helperText={
                error && error.type === ErrorType.SignUpPassword ? (
                  <Typography variant="body2" color="red">
                    {error.message}
                  </Typography>
                ) : null
              }
              value={signUpPassword}
              type="password"
              onChange={(e) => {
                setError(null);
                setSignUpPassword(e.target.value);
              }}
            />
            <TextField
              sx={{ marginTop: 2 }}
              id="outlined-basic"
              label="Repeat Password"
              variant="outlined"
              helperText={
                error && error.type === ErrorType.SignUpRepeatPassword ? (
                  <Typography variant="body2" color="red">
                    {error.message}
                  </Typography>
                ) : null
              }
              value={signUpRepeatPassword}
              type="password"
              onChange={(e) => {
                setError(null);
                setSignUpRepeatPassword(e.target.value);
              }}
            />
            <Typography variant="body2" color="red" sx={{ height: 20 }}>
              {error && error.type === ErrorType.SignUpPassword
                ? error.message
                : null}
            </Typography>
            <Button sx={{ marginTop: 2 }} variant="contained" onClick={signUp}>
              Sign Up
            </Button>
            <FacebookLoginButton
              style={{ marginTop: 20 }}
              onClick={loginFacebook}
            />
            <GoogleLoginButton onClick={loginGoogle} />
          </Paper>
        </>
      )}
      {status === LoginStatus.SignUpCompleted && (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography variant="h5">Sign Up Completed</Typography>
            <Typography
              variant="body2"
              sx={{ marginTop: 2 }}
              textAlign="center"
            >
              Thank you for your registration. Please complete your registration
              by clicking the link we sent to <br />
              {signUpEmail}.
            </Typography>
          </Box>
        </>
      )}
    </Box>
  );
}

export default Login;
