import React from "react";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { isValidPassword, IValidationResult } from "../Tools";
import { useParams, useNavigate } from "react-router-dom";
import { q } from "../Tools";
import { GlobalContext, IGlobalContext } from "../GlobalContextProvider";

const lfCfg = require("../lf-config-frontend.json");
interface IError {
  type: string;
  message?: string;
}

enum ResetPasswordStatus {
  ResettingPassword,
  PasswordResetted,
}

const ResetPassword: React.FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [status, setStatus] = React.useState(
    ResetPasswordStatus.ResettingPassword
  );
  const [password, setPassword] = React.useState<string>("");
  const [passwordRepeat, setPasswordRepeat] = React.useState<string>("");
  const globalContext: IGlobalContext = React.useContext(GlobalContext);
  const [error, setError] = React.useState<IError | null>(null);

  const resetPassword = async () => {
    const validationResult: IValidationResult = isValidPassword(password);
    if (validationResult.errorId !== 0) {
      setError({ type: "ERROR", message: validationResult.message });
      return;
    }

    if (password !== passwordRepeat) {
      setError({ type: "ERROR", message: "Passwords do not match" });
      return;
    }

    try {
      const result = await fetch(lfCfg.backend + "/_resetpassword", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          password,
        }),
      });

      const data = await result.json();
      if (data.errorId === 0) {
        q(`password resetted: ${JSON.stringify(data)}`);
        globalContext.dispatch({
          type: "SET_RESETTING_PASSWORD",
          value: false,
        });
        setStatus(ResetPasswordStatus.PasswordResetted);
        setTimeout(
          () =>
            (window.location.href = `${lfCfg.frontend}/${globalContext.state.user?.username}`),
          3000
        );
      } else {
        globalContext.dispatch({
          type: "SET_RESETTING_PASSWORD",
          value: false,
        });
        setError({
          type: "ERROR",
          message: data.message,
        });
      }
    } catch (e) {
      globalContext.dispatch({
        type: "SET_RESETTING_PASSWORD",
        value: false,
      });
      setError({
        type: "ERROR",
        message: e as string,
      });
    }
  };

  const login = async () => {
    try {
      const result = await fetch(
        lfCfg.backend + "/_resetpasswordlogin/" + params.code,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = await result.json();
      if (data.errorId === 0) {
        q(`user logged in: ${JSON.stringify(data)}`);
        globalContext.dispatch({
          type: "SET_USER",
          value: data.user,
        });
        globalContext.dispatch({
          type: "SET_RESETTING_PASSWORD",
          value: true,
        });
      } else {
        q(`user not logged in: ${JSON.stringify(data)}`);
        globalContext.dispatch({
          type: "SET_RESETTING_PASSWORD",
          value: false,
        });
        navigate("/");
      }
    } catch (e) {
      q(`user not logged in: ${JSON.stringify(e)}`);
      globalContext.dispatch({
        type: "SET_RESETTING_PASSWORD",
        value: false,
      });
      navigate("/");
    }
  };

  React.useEffect(() => {
    login();
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 10,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 10,
        }}
      >
        {status === ResetPasswordStatus.ResettingPassword && (
          <>
            <Typography variant="h5">Reset Password</Typography>
            <Box>
              <TextField
                sx={{ marginTop: 2 }}
                id="outlined-basic"
                label="Password"
                variant="outlined"
                value={password}
                onChange={(e) => {
                  setError(null);
                  setPassword(e.target.value);
                }}
                type="password"
              />
            </Box>
            <Box>
              <TextField
                sx={{ marginTop: 2 }}
                id="outlined-basic"
                label="Password Repeat"
                variant="outlined"
                value={passwordRepeat}
                onChange={(e) => {
                  setError(null);
                  setPasswordRepeat(e.target.value);
                }}
                type="password"
              />
              <Typography variant="body2" color="red">
                {error ? error.message : null}
              </Typography>
            </Box>
            <Button
              sx={{ marginTop: 2 }}
              variant="contained"
              onClick={resetPassword}
            >
              Change Password
            </Button>
          </>
        )}
        {status === ResetPasswordStatus.PasswordResetted && (
          <>
            <Typography variant="h5" color="green">
              Your password is resetted.
            </Typography>
          </>
        )}
      </Box>
    </Box>
  );
};

export default ResetPassword;
