function TermsRaw() {
  return (
    <>
      <h3>Terms of Service</h3>
      <h3>1. Acceptance of Terms</h3>
      <p>
        Welcome to Linkbox.io. These Terms of Service ("Terms") govern your
        access to and use of Linkbox.io ("we", "us", or "our"), including our
        website and the services we provide (the "Services"). By using our
        Services, you are agreeing to these Terms. Please read them carefully.
      </p>

      <h3>2. Eligibility</h3>
      <p>
        You must be at least 18 years old to use our Services. By agreeing to
        these Terms, you represent and warrant to us that: you are at least 18
        years old, you have not previously been suspended or removed from our
        Services, and your registration and your use of our Services is in
        compliance with any and all applicable laws and regulations.
      </p>

      <h3>3. Use at Your Own Risk</h3>
      <p>
        Our Services allow you to save and share links with other users.
        Clicking on such links will redirect you to third-party websites. These
        links may be posted by us or by other users. Please note that we do not
        control these third-party websites and we are not responsible for the
        content, products, or services on or available from those websites. The
        inclusion of any link does not imply endorsement by Linkbox.io of the
        website. Use of any such linked website is at the user's own risk.
      </p>

      <h3>4. User Responsibilities</h3>
      <p>
        As a user of Linkbox.io, you agree to only save and share links that are
        not harmful and do not distribute pornography of any kind, are not
        disturbing, offensive, illegal or in violation of any law, regulation,
        or our community guidelines. You are solely responsible for all content
        you provide and the interactions with other users, and we reserve the
        right, but are not obligated, to monitor any such content or
        interactions.
      </p>

      <h3>5. Termination</h3>
      <p>
        We reserve the right to terminate or suspend your access to our Services
        at any time and for any reason, including but not limited to a breach of
        these Terms. If you violate these Terms, we reserve the right to take
        any action that we deem appropriate, including removing your content or
        terminating your access to our Services.
      </p>

      <h3>6. Changes to These Terms</h3>
      <p>
        We may revise these Terms from time to time, and the most current
        version will always be posted on our website. If a revision, in our sole
        discretion, is material, we will notify you. By continuing to access or
        use our Services after revisions become effective, you agree to be bound
        by the revised Terms.
      </p>

      <h3>7. Governing Law and Jurisdiction</h3>
      <p>
        These Terms and any dispute or claim arising out of or in connection
        with them or their subject matter or formation (including
        non-contractual disputes or claims) shall be governed by and construed
        in accordance with the law of our registered jurisdiction.
      </p>

      <h3>8. Cookies</h3>
      <p>
        We use cookies and similar technologies to provide our Services and to
        collect data for analytics purposes. By using our Services and agreeing
        to these Terms, you consent to our use of cookies in accordance with the
        terms of our Privacy Policy. You can control your cookie settings from
        within your web browser, but please note that if you choose to reject
        cookies, some parts of our Services may not be available or functional.
      </p>

      <p>Please contact us if you have any questions about these Terms.</p>
      <p>Last updated: May 19th, 2023</p>
    </>
  );
}

export default TermsRaw;
