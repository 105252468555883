import { q } from "./Tools";
import { IUser } from "./GlobalContextProvider";
const lfCfg = require("./lf-config-frontend.json");

export const loadAuthStatus = async (): Promise<IUser | null> => {
  return new Promise(async (resolve, reject) => {
    q(`checking if user is authenticated ...`);
    try {
      const result = await fetch(lfCfg.backend + `/_authstatus`, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = await result.json();
      if (data.errorId === 0) {
        q(`user is logged in as ${JSON.stringify(data.user.username)}`);
        return resolve(data.user);
      } else {
        q(`user is not logged in`);
        return resolve(null);
      }
    } catch (e) {
      q(`Error doing fetch request: ${e}`);
      return reject(false);
    }
  });
};

export const loadAcceptedTermsStatus = async (): Promise<boolean> => {
  return new Promise(async (resolve, reject) => {
    q(`checking if user has accepted the terms ...`);
    try {
      const result = await fetch(lfCfg.backend + `/_termsaccepted`, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = await result.json();
      if (data.errorId === 0) {
        q(`user has accepted terms: ${JSON.stringify(data.termsAccepted)}`);
        return resolve(data.termsAccepted);
      }
    } catch (e) {
      q(`Error doing fetch request: ${e}`);
      return reject(false);
    }
  });
};
