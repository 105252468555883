import React from "react";
import { Route, Routes } from "react-router-dom";

import Header from "./Header";
import Root from "./components/Root";
import MyProfile from "./components/MyProfile";
import Login from "./components/Login";
import Box from "@mui/material/Box";
import Activate from "./components/Activate";
import Setup from "./components/Setup";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";
import Terms from "./components/Terms";
import Imprint from "./components/Imprint";
import Repository from "./components/Repository";
import AuthSuccess from "./components/AuthSuccess";
import Sandbox from "./components/Sandbox";
import TermsDialog from "./components/TermsDialog";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import useMediaQuery from "@mui/material/useMediaQuery";
import Player from "./Player";
import { useLocation, useNavigate } from "react-router-dom";
import { IGlobalContext, GlobalContext } from "./GlobalContextProvider";
import { loadAuthStatus, loadAcceptedTermsStatus } from "./Auth";
import { IUser } from "./GlobalContextProvider";
import { q } from "./Tools";
import "./App.css";
const lfCfg = require("./lf-config-frontend.json");

const darkTheme = createTheme({
  palette: {
    primary: {
      light: "#a4a4a4", // lighter shade of gray
      main: "#737373", // main gray color
      dark: "#404040", // darker shade of gray
      contrastText: "#fff",
    },
    secondary: {
      light: "#888888", // light gray
      main: "#606060", // main gray color
      dark: "#333333", // dark gray color
      contrastText: "#fff",
    },
    background: {
      default: "#ffffff", // white background color
      paper: "#ffffff", // white color for paper-based components
    },
    text: {
      primary: "#404040", // dark gray for most text
      secondary: "#606060", // slightly lighter gray for secondary text
    },
  },
});

function App() {
  const isSmallMedia = useMediaQuery("(max-width:600px)");
  const [authStatusChecked, setAuthStatusChecked] =
    React.useState<boolean>(false);
  const [showTerms, setShowTerms] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();
  const globalContext: IGlobalContext = React.useContext(GlobalContext);

  React.useEffect(() => {
    globalContext.dispatch({
      type: "SET_SMALLMEDIA",
      value: isSmallMedia,
    });
  }, [isSmallMedia]);

  React.useEffect(() => {
    const _loadAuthStatus = async () => {
      const user: IUser | null = await loadAuthStatus();
      // a user who is logged in can go to protected routes
      if (user) {
        globalContext.dispatch({ type: "SET_USER", value: user });
        setAuthStatusChecked(true);
        // if the user does not have a username yet, he is supposed to
        // setup up his account
        if (user.username === null) {
          navigate("/_setup");
        }
      } else {
        globalContext.dispatch({ type: "SET_USER", value: null });
        setAuthStatusChecked(true);
        // if the user is not logged in and browses to
        // protected routes, send him back to root
        if (
          location.pathname.startsWith("/_myprofile") ||
          location.pathname.startsWith("/_setup")
        ) {
          navigate("/");
        }

        const hasUserAcceptedTerms = await loadAcceptedTermsStatus();

        if (!hasUserAcceptedTerms) {
          setShowTerms(true);
        }
      }
    };

    _loadAuthStatus();
  }, []);

  const cancelAcceptTerms = () => {
    window.location.href = "https://www.google.com";
  };

  const acceptTerms = async () => {
    q(`accepting terms of services ...`);
    try {
      const result = await fetch(lfCfg.backend + `/_termsaccepted`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = await result.json();
      if (data.errorId === 0) {
        q(`user has accepted terms`);
        setShowTerms(false);
      }
    } catch (e) {
      q(`Error doing fetch request: ${e}`);
    }
  };

  return (
    <>
      {authStatusChecked && (
        <ThemeProvider theme={darkTheme}>
          <CssBaseline />
          <Header />
          <Box sx={{ marginTop: isSmallMedia ? 14 : 8, height: 500 }}>
            <Routes>
              <Route path="/" element={<Root />} />
              <Route path="/_myprofile" element={<MyProfile />} />
              <Route path="/_login" element={<Login />} />
              <Route path="/_activate/:code" element={<Activate />} />
              <Route path="/_setup" element={<Setup />} />
              <Route path="/_forgotpassword" element={<ForgotPassword />} />
              <Route path="/_resetpassword/:code" element={<ResetPassword />} />
              <Route path="/_terms" element={<Terms />} />
              <Route path="/_imprint" element={<Imprint />} />
              <Route path="/_sandbox" element={<Sandbox />} />
              <Route path="/_authsuccess" element={<AuthSuccess />} />
              <Route path="/:path*" element={<Repository />} />
            </Routes>
          </Box>
          <TermsDialog
            open={showTerms}
            handleCancel={cancelAcceptTerms}
            handleAccept={acceptTerms}
          />
          {globalContext.state.player ? <Player /> : null}
        </ThemeProvider>
      )}
    </>
  );
}

export default App;
