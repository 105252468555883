import React from "react";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { q } from "../../Tools";
import { GlobalContext, IGlobalContext } from "../../GlobalContextProvider";
const lfCfg = require("../../lf-config-frontend.json");

interface IMyProfile {
  username: string;
  numlinks: number;
  max_numlinks: number;
  visibility: string;
}

function General() {
  const [myProfile, setMyProfile] = React.useState<IMyProfile | null>(null);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    changeVisibility((event.target as HTMLInputElement).value);
  };
  const globalContext: IGlobalContext = React.useContext(GlobalContext);

  const loadMyProfile = async () => {
    q(`loading my profile ...`);
    try {
      const result = await fetch(lfCfg.backend + "/_myprofile", {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = await result.json();
      if (data.errorId === 0) {
        q(`loaded general: ${JSON.stringify(data)}`);
        setMyProfile(data.myProfile);
      } else {
        q(`could not load myprofile: ${data.message}`);
      }
    } catch (e) {
      q(`could not load myprofile: ${e}`);
    }
  };

  React.useEffect(() => {
    loadMyProfile();
  }, []);

  const changeVisibility = async (visibility: string) => {
    q(`changing visibility ...`);
    try {
      const result = await fetch(lfCfg.backend + "/_visibility", {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ visibility }),
      });

      const data = await result.json();
      if (data.errorId === 0) {
        q(`changed visibility: ${JSON.stringify(data)}`);
        setMyProfile(myProfile ? { ...myProfile, visibility } : null);
      } else {
        q(`could not change visibility: ${data.message}`);
      }
    } catch (e) {
      q(`could not change visibility: ${e}`);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: 450,
      }}
    >
      <Typography variant="h5">My Profile</Typography>
      <Box sx={{ marginTop: 4 }}>
        <Typography variant="body2">Linkbox URL:</Typography>
        <TextField
          sx={{ marginTop: 2 }}
          id="outlined-basic"
          variant="outlined"
          value={`${lfCfg.frontend}/${globalContext.state.user?.username}`}
          disabled
        />
      </Box>

      <Box sx={{ marginTop: 4 }}>
        <Typography variant="body2">Storage:</Typography>

        <TextField
          sx={{ marginTop: 2 }}
          id="outlined-basic"
          // label="Storage"
          variant="outlined"
          value="218 of 1000 links (22%)"
          disabled
        />
      </Box>

      <Typography variant="h5" sx={{ marginTop: 4 }}>
        Visibility
      </Typography>
      {myProfile && (
        <Box sx={{ marginTop: 2 }}>
          <FormControl>
            <RadioGroup value={myProfile?.visibility} onChange={handleChange}>
              <FormControlLabel
                value="public"
                control={<Radio />}
                label={
                  <Typography variant="body2">
                    My linkbox will be visible to others (public)
                  </Typography>
                }
              />
              <FormControlLabel
                value="private"
                control={<Radio />}
                label={
                  <Typography variant="body2">
                    My linkbox will not be visible to others (private)
                  </Typography>
                }
              />
            </RadioGroup>
          </FormControl>
        </Box>
      )}
    </Box>
  );
}

export default General;
