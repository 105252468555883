import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import { ITreeNode } from "./MoveNodeTreeView";
import { INode } from "./Repository";
import Typography from "@mui/material/Typography";
import MoveNodeTreeView from "./MoveNodeTreeView";
import { IGlobalContext, GlobalContext } from "../GlobalContextProvider";

import { q } from "../Tools";

const lfCfg = require("../lf-config-frontend.json");

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export interface IMoveNodeDialogProps {
  open: boolean;
  onCloseHandler: () => void;
  onMoveNode: (movingNode: ITreeNode) => void;
  movingNode: INode | null;
}

const MoveNodeDialog: React.FC<IMoveNodeDialogProps> = ({
  open,
  onMoveNode,
  onCloseHandler,
  movingNode,
}) => {
  const globalContext: IGlobalContext = React.useContext(GlobalContext);

  // const nodes: ITreeNode = {
  //   type: "folder",
  //   name: "jan",
  //   path: "/jan",
  //   subnodes: [
  //     {
  //       type: "folder",
  //       name: "music",
  //       path: "/jan/music",
  //       subnodes: [
  //         {
  //           type: "link",
  //           name: "song1",
  //           path: "/jan/music/l1",
  //         },
  //         {
  //           type: "link",
  //           name: "song2",
  //           path: "/jan/music/l2",
  //         },
  //         {
  //           type: "link",
  //           name: "song3",
  //           path: "/jan/music/l3",
  //         },
  //       ],
  //     },
  //     {
  //       type: "folder",
  //       name: "news",
  //       path: "/jan/news",
  //       subnodes: [
  //         { type: "link", name: "news1", path: "/jan/news/l1" },
  //         { type: "link", name: "news2", path: "/jan/news/l2" },
  //         { type: "link", name: "news3", path: "/jan/news/l3" },
  //       ],
  //     },
  //     { type: "link", name: "bla1", path: "/jan/l1" },
  //     { type: "link", name: "bla2", path: "/jan/l2" },
  //     { type: "link", name: "bla3", path: "/jan/l3" },
  //   ],
  // };
  const [nodes, setNodes] = React.useState<ITreeNode | null>();
  const [selectedPath, setSelectedPath] = React.useState("");
  const [targetPath, setTargetPath] = React.useState("");
  const handleSelectPath = (path: string) => {
    setSelectedPath(path);
  };
  const [error, setError] = React.useState<string | null>(null);

  React.useEffect(() => {
    const loadUserTree = async () => {
      if (!globalContext.state.user) return null;

      try {
        const result = await fetch(
          `${lfCfg.backend}/_tree/${globalContext.state.user.username}`,
          {
            method: "GET",
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const data = await result.json();
        if (data.errorId === 0) {
          setNodes(data.tree as ITreeNode);
          q(`loaded user tree: ${JSON.stringify(data)}`);
        }
      } catch (e) {
        setError(e as string);
      }
    };

    if (open) loadUserTree();
  }, [open]);

  const moveNode = async () => {
    if (!movingNode) return null;

    try {
      const result = await fetch(lfCfg.backend + movingNode.path, {
        method: "PATCH",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          targetPath: selectedPath,
        }),
      });

      const data = await result.json();
      if (data.errorId === 0) {
        q(
          `moved node from ${
            movingNode.path
          } to ${targetPath}: ${JSON.stringify(data)}`
        );
        onMoveNode(movingNode);
        onCloseHandler();
      } else {
        q(`could not move node: ${JSON.stringify(data)}`);
        setError(data.message);
      }
    } catch (e) {
      q(`could not move node: ${JSON.stringify(e)}`);
      setError(e as string);
    }
  };

  return (
    <Dialog
      onClose={onCloseHandler}
      aria-labelledby="customized-dialog-title"
      open={open}
      PaperProps={{ sx: { width: "40%", minWidth: 400 } }}
    >
      <BootstrapDialogTitle
        id="customized-dialog-title"
        onClose={onCloseHandler}
      >
        Move to Folder
      </BootstrapDialogTitle>
      <DialogContent dividers>
        <Typography variant="body2">Please select a target folder:</Typography>
        <Box sx={{ marginTop: 2 }}>
          {nodes && (
            <MoveNodeTreeView
              rootNode={nodes}
              onSelectPathHandler={handleSelectPath}
            />
          )}
        </Box>
        <Typography variant="body2" sx={{ height: 24, marginTop: 2 }}>
          {selectedPath.length > 0 ? `Move to ${selectedPath}` : null}
        </Typography>
        <Typography
          variant="body2"
          sx={{ height: 24, marginTop: 2 }}
          color="red"
        >
          {error && `Error: ${error}`}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={moveNode}>
          OK
        </Button>
        <Button autoFocus onClick={onCloseHandler}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MoveNodeDialog;
