import * as React from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import Typography from "@mui/material/Typography";
import { useLocation, useNavigate } from "react-router-dom";
import { ILink } from "./Repository";
import { q } from "../Tools";
const lfCfg = require("../lf-config-frontend.json");

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export interface IAddLinkDialogProps {
  open: boolean;
  onCloseHandler: () => void;
  editLink: ILink | null;
  onAddLink: (newLink: ILink) => void;
  onSaveLink: (editLink: ILink) => void;
}

const AddOrEditLinkDialog: React.FC<IAddLinkDialogProps> = ({
  open,
  onCloseHandler,
  editLink,
  onAddLink,
  onSaveLink,
}) => {
  const [error, setError] = React.useState<string | null>(null);
  const [parsing, setParsing] = React.useState<boolean>(false);
  const [url, setUrl] = React.useState<string>("");
  const [title, setTitle] = React.useState<string>(
    editLink ? editLink.title : ""
  );
  const location = useLocation();

  React.useEffect(() => {
    if (!editLink) {
      setError(null);
      setUrl("");
      setTitle("");
    } else {
      setError(null);
      setTitle(editLink.title);
    }
  }, [open]);

  const addLink = async () => {
    setParsing(true);

    let completeUrl = url;
    if (!(completeUrl.startsWith("http") || completeUrl.startsWith("https"))) {
      completeUrl = "https://" + completeUrl;
    }

    try {
      const result = await fetch(lfCfg.backend + location.pathname, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          type: "link",
          url: completeUrl,
        }),
      });

      const data = await result.json();
      if (data.errorId === 0) {
        setParsing(false);
        setError(null);
        q(`added link: ${JSON.stringify(data)}`);
        onAddLink(data.newLink as ILink);
      } else {
        setParsing(false);
        setError(data.message);
      }
    } catch (e) {
      setError(e as string);
    }
  };

  const saveLink = async () => {
    if (!editLink || !onSaveLink) return null;

    q(`saving link ...`);
    try {
      const result = await fetch(lfCfg.backend + editLink.path, {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          title,
        }),
      });

      const data = await result.json();
      if (data.errorId === 0) {
        q(`saved link: ${JSON.stringify(data)}`);
        onSaveLink({ ...editLink, title } as ILink);
      } else {
        q(`could not save link: ${JSON.stringify(data)}`);
        setError(data.message);
      }
    } catch (e) {
      q(`could not save link: ${JSON.stringify(e)}`);
      setError(e as string);
    }
  };

  return (
    <Dialog
      onClose={onCloseHandler}
      aria-labelledby="customized-dialog-title"
      open={open}
      PaperProps={{ sx: { width: "40%", minWidth: 400 } }}
    >
      <BootstrapDialogTitle
        id="customized-dialog-title"
        onClose={onCloseHandler}
      >
        {editLink ? "Edit Link" : "Add Link"}
      </BootstrapDialogTitle>
      <DialogContent dividers>
        {!parsing ? (
          <>
            <Typography variant="body2">
              {editLink
                ? "Please put a valid title here:"
                : "Please put a URL here:"}
            </Typography>
            {editLink ? (
              <TextField
                id="standard-basic"
                label=""
                variant="outlined"
                style={{ marginTop: 16, width: "100%" }}
                onChange={(e) => {
                  setTitle(e.currentTarget.value);
                  setError(null);
                }}
                value={title}
              />
            ) : (
              <TextareaAutosize
                aria-label="minimum height"
                minRows={3}
                onChange={(e) => {
                  setUrl(e.currentTarget.value);
                  setError(null);
                }}
                value={url}
                placeholder="https://www.google.com"
                style={{ marginTop: 16, width: "100%" }}
              />
            )}
            <Typography
              variant="body2"
              color={error ? "red" : "green"}
              style={{ height: 20 }}
            >
              {error ? `Invalid domain: ${error}` : null}
            </Typography>
          </>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 114,
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={editLink ? saveLink : addLink}>
          OK
        </Button>
        <Button autoFocus onClick={onCloseHandler}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddOrEditLinkDialog;
