import * as React from "react";
import Box from "@mui/material/Box";
import SearchIcon from "@mui/icons-material/Search";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { q } from "../Tools";
import FolderIcon from "@mui/icons-material/Folder";
import {
  GlobalContext,
  IGlobalContext,
  ISearchResult,
  SearchResultType,
} from "../GlobalContextProvider";
import { ILink } from "./Repository";
const lfCfg = require("../lf-config-frontend.json");

export interface IRootFolder {
  name: string;
  numlinks: number;
  type: string;
  path: string;
}

enum EnumRootStatus {
  ShowRootFolders,
  ShowSearchResults,
}

export default function Root() {
  const navigate = useNavigate();
  const [status, setStatus] = React.useState<EnumRootStatus>(
    EnumRootStatus.ShowRootFolders
  );
  const globalContext: IGlobalContext = React.useContext(GlobalContext);
  const [rootFolders, setRootFolders] = React.useState<IRootFolder[]>([]);

  const { search } = globalContext.state;

  const loadRoot = async () => {
    q(`loading root folder ...`);
    try {
      const result = await fetch(lfCfg.backend + "/", {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = await result.json();
      if (data.errorId === 0) {
        q(`loaded root folder: ${JSON.stringify(data)}`);
        setStatus(EnumRootStatus.ShowRootFolders);
        setRootFolders(data.rootFolders as IRootFolder[]);
      } else {
        q(`could not load root folder`);
      }
    } catch (e) {
      q(`error loading root folder: ${e}`);
    }
  };

  const clickRootFolder = (rootFolder: IRootFolder) => {
    navigate(rootFolder.path);
  };

  React.useEffect(() => {
    loadRoot();
  }, []);

  React.useEffect(() => {
    loadRoot();

    globalContext.dispatch({
      type: "SET_APP_TITLE",
      value: "Linkbox",
    });
  }, []);

  const showRootFolders = () => {
    return rootFolders.map((rootFolder: IRootFolder) => (
      <Box
        className="repo-node"
        sx={{
          display: "flex",
          height: 32,
          alignItems: "center",
          cursor: "pointer",
          borderBottom: "0.5px solid",
          borderBottomColor: "rgb(230, 230, 230)",
        }}
        onClick={() => clickRootFolder(rootFolder)}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <FolderIcon color="primary" />
        </Box>
        <Box
          sx={{
            marginLeft: 1,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          {rootFolder.name}
        </Box>
        <Box sx={{ marginLeft: "auto" }}>{rootFolder.numlinks} links</Box>
      </Box>
    ));
  };

  const clickSearchResult = (node: ISearchResult, popUp: boolean) => {
    if (node.type === "folder") {
      navigate(node.path);
    } else {
      // todo, add check if player is enabled
      if (popUp || !node.embedcode) {
        window.open(node.url);
        return;
      }

      let playable = false;
      // if the user is loggedin, he can save what domain will be played and what not (in settings menu)
      // if he is not logged in, every playable domain will be played
      if (
        globalContext.state.user !== null &&
        globalContext.state.user.playerSettings !== null
      ) {
        // check if the domain is in the list of played domains
        for (let playerSetting of globalContext.state.user.playerSettings) {
          if (
            playerSetting.domain === node.domainname &&
            playerSetting.enabled
          ) {
            playable = true;
            break;
          }
        }
      } else {
        playable = true;
      }

      if (playable && node.embedcode) {
        globalContext.dispatch({
          type: "SET_PLAYER",
          value: {
            html: node.embedcode
              .replace("%LBXWIDTH", "500")
              .replace("%LBXHEIGHT", "300"),
            width: 492,
            height: 340,
            minimized: false,
          },
        });
      } else {
        window.open(node.url);
        return;
      }
    }
  };

  const showSearchResults = () => {
    if (search.searchResults.length > 0) {
      return search.searchResults.map((searchResult: ISearchResult) => (
        <Box
          className="repo-node"
          sx={{
            display: "flex",
            height: 32,
            alignItems: "center",
            cursor: "pointer",
            borderBottom: "0.5px solid",
            borderBottomColor: "rgb(230, 230, 230)",
          }}
          onClick={() => clickSearchResult(searchResult, false)}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {searchResult.type === SearchResultType.Link ? (
              <img
                src={`${lfCfg.frontend}/${lfCfg.favIconsFolderName}/${searchResult.domain}.png`}
                style={{
                  display: "block",
                  width: 20,
                  height: 20,
                  marginTop: 2,
                }}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = `${lfCfg.frontend}/default_domain.png`;
                }}
              />
            ) : (
              <FolderIcon color="primary" />
            )}
          </Box>
          <Box
            sx={{
              marginLeft: 1,
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            {searchResult.name}
          </Box>
          <Box sx={{ marginLeft: "auto" }}>{searchResult.path}</Box>
        </Box>
      ));
    }

    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: 400,
        }}
      >
        <SearchIcon />
        <Typography variant="h6">No results.</Typography>
      </Box>
    );
  };

  return (
    <Box>
      {search.searchResults.length === 0 && search.searchQuery.length === 0
        ? showRootFolders()
        : showSearchResults()}
    </Box>
  );
}
