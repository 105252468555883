import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { GlobalContext, IPlayerSetting } from "../../GlobalContextProvider";
import { q } from "../../Tools";
const lfCfg = require("../../lf-config-frontend.json");

function Players() {
  const [playerSettings, setPlayerSettings] = React.useState<
    IPlayerSetting[] | null
  >(null);
  const myGlobalContext = React.useContext(GlobalContext);

  const loadPlayerSettings = async () => {
    q(`loading player settings ...`);

    try {
      const result = await fetch(lfCfg.backend + "/_playersettings", {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = await result.json();
      if (data.errorId === 0) {
        q(`loaded player settings: ${JSON.stringify(data)}`);
        setPlayerSettings(data.playerSettings);
      } else {
        q(`could not load player settings: ${data.message}`);
      }
    } catch (e) {
      q(`could not load player settings: ${e}`);
    }
  };

  // changes the player settings
  const togglePlayerSetting = async (id: string, newSetting: boolean) => {
    q(`toggling player status ...`);
    try {
      const result = await fetch(
        lfCfg.backend + `/_playersetting/${id}/${newSetting}`,
        {
          method: "PUT",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = await result.json();
      if (data.errorId === 0) {
        q(`loaded player settings: ${JSON.stringify(data)}`);

        // todo: unify global and local playerSettings
        const newPlayerSettings =
          playerSettings &&
          playerSettings.map((playerSetting) =>
            playerSetting.id === id
              ? { ...playerSetting, enabled: newSetting }
              : playerSetting
          );
        setPlayerSettings(newPlayerSettings);
        if (!newPlayerSettings) return;
        myGlobalContext.dispatch({
          type: "SET_PLAYER_SETTINGS",
          value: newPlayerSettings,
        });
      } else {
        q(`could not load player settings: ${data.message}`);
      }
    } catch (e) {
      q(`could not load player settings: ${e}`);
    }
  };

  React.useEffect(() => {
    loadPlayerSettings();
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: 450,
      }}
    >
      <Box sx={{ maxWidth: 400 }}>
        <Typography variant="h5">Players</Typography>
        <Typography variant="body2" sx={{ marginTop: 4 }}>
          For links of the following domains, check or uncheck whether they
          should be opened with an embed player
        </Typography>
        <Box
          sx={{
            // display: "flex",
            // justifyContent: "center",
            marginTop: 4,
          }}
        >
          <FormGroup>
            {playerSettings &&
              playerSettings.map((playerSetting) => (
                <FormControlLabel
                  control={<Checkbox defaultChecked />}
                  label={playerSetting.domain}
                  checked={playerSetting.enabled}
                  onClick={() =>
                    togglePlayerSetting(
                      playerSetting.id,
                      !playerSetting.enabled
                    )
                  }
                />
              ))}
          </FormGroup>
        </Box>
      </Box>
    </Box>
  );
}

export default Players;
