import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { useLocation } from "react-router-dom";
import { IFolder } from "./Repository";
import { q } from "../Tools";
const lfCfg = require("../lf-config-frontend.json");

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export interface IAddOrEditLinkDialogProps {
  open: boolean;
  onCloseHandler: () => void;
  editFolder: IFolder | null;
  onAddFolder: (newFolder: IFolder) => void;
  onSaveFolder: (editFolder: IFolder) => void;
  parentFolderType: string;
}

const AddOrEditLinkDialog: React.FC<IAddOrEditLinkDialogProps> = ({
  open,
  onCloseHandler,
  onAddFolder,
  editFolder,
  onSaveFolder,
  parentFolderType,
}) => {
  const [name, setName] = React.useState("");
  const [error, setError] = React.useState<string | null>(null);
  const [folderType, setFolderType] = React.useState<string>(
    parentFolderType === "root" ? "public" : parentFolderType
  );
  const location = useLocation();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFolderType((event.target as HTMLInputElement).value);
  };

  React.useEffect(() => {
    if (!editFolder) {
      setError(null);
      setName("");
      setFolderType("public");
    } else {
      setError(null);
      setName(editFolder.name);
      setFolderType(editFolder.foldertype);
    }
  }, [open]);

  const addFolder = async () => {
    try {
      const result = await fetch(lfCfg.backend + location.pathname, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          type: "folder",
          name: name,
          folderType: folderType,
        }),
      });

      setName("");
      const data = await result.json();
      if (data.errorId === 0) {
        q(`added folder: ${JSON.stringify(data)}`);
        data.newFolder.domain = "folder"; // quick hack to prevent sorting from fucking up
        onAddFolder(data.newFolder as IFolder);
      } else {
        setError(data.message);
      }
    } catch (e) {
      setError(e as string);
    }
  };

  const saveFolder = async () => {
    if (!editFolder || !onSaveFolder) return null;

    editFolder.name = name;
    editFolder.foldertype = folderType;

    q(`saving folder ...`);
    try {
      const result = await fetch(lfCfg.backend + editFolder.path, {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: editFolder.name,
          foldertype: editFolder.foldertype,
        }),
      });

      const data = await result.json();
      if (data.errorId === 0) {
        q(`saved folder: ${JSON.stringify(data)}`);
        onSaveFolder(editFolder);
      } else {
        q(`could not save FOLDER: ${JSON.stringify(data)}`);
        setError(data.message);
      }
    } catch (e) {
      q(`could not save link: ${JSON.stringify(e)}`);
      setError(e as string);
    }
  };

  return (
    <Dialog
      onClose={onCloseHandler}
      aria-labelledby="customized-dialog-title"
      open={open}
      PaperProps={{ sx: { width: "40%", minWidth: 400 } }}
    >
      <BootstrapDialogTitle
        id="customized-dialog-title"
        onClose={onCloseHandler}
      >
        {editFolder ? "Edit Folder" : "Add Folder"}
      </BootstrapDialogTitle>
      <DialogContent dividers>
        <Typography variant="body2">
          Please put a valid folder name here:
        </Typography>
        <TextField
          id="standard-basic"
          label=""
          variant="outlined"
          style={{ marginTop: 16, width: "100%" }}
          onChange={(e) => {
            setError(null);
            setName(e.currentTarget.value);
          }}
          value={name}
        />
        <Typography variant="body2" color="red" style={{ height: 20 }}>
          {error ? error : null}
        </Typography>
        {parentFolderType === "root" && (
          <FormControl>
            <RadioGroup value={folderType} onChange={handleChange}>
              <FormControlLabel
                value="public"
                control={<Radio />}
                label={
                  <Typography variant="body2">
                    Everyone can see this folder and its contents (public)
                  </Typography>
                }
              />
              <FormControlLabel
                value="private"
                control={<Radio />}
                label={
                  <Typography variant="body2">
                    Only you can see this folder and its contents (private)
                  </Typography>
                }
              />
            </RadioGroup>
          </FormControl>
        )}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={editFolder ? saveFolder : addFolder}>
          OK
        </Button>
        <Button autoFocus onClick={onCloseHandler}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddOrEditLinkDialog;
