import * as React from "react";
import { nodes } from "./dummyFolder";
import Box from "@mui/material/Box";
import FolderIcon from "@mui/icons-material/Folder";
import Typography from "@mui/material/Typography";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import SearchIcon from "@mui/icons-material/Search";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import LinkIcon from "@mui/icons-material/Link";
import MoveUpIcon from "@mui/icons-material/MoveUp";
import EnhancedEncryptionIcon from "@mui/icons-material/EnhancedEncryption";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import MoveNodeDialog from "./MoveNodeDialog";
import RepoFAB from "./RepoFAB";
import { useLocation, useNavigate } from "react-router-dom";
import AddOrEditLinkDialog from "./AddOrEditLinkDialog";
import AddOrEditFolderDialog from "./AddOrEditFolderDialog";
import { ITreeNode } from "./MoveNodeTreeView";
import {
  GlobalContext,
  IGlobalContext,
  ISearchResult,
  SearchResultType,
} from "../GlobalContextProvider";
import { q } from "../Tools";
import EditIcon from "@mui/icons-material/Edit";

const lfCfg = require("../lf-config-frontend.json");

interface IDomainInfo {
  tld: string;
  domain: string;
  subdomain: string;
}

export interface ILink {
  type: string;
  name: string;
  path: string;
  url: string;
  title: string;
  domaininfo: IDomainInfo;
  domain: string;
  domainname: string;
  created: string;
  embedcode?: string | null;
  xname?: string;
}

export interface IFolder {
  type: string;
  name: string;
  foldertype: string;
  owner: string;
  path: string;
  created?: string;
  domain: string;
  subnodes: Array<INode | IFolder>;
}

export type INode = IFolder | ILink;

enum ShowDialogType {
  MoveNode,
  AddLink,
  EditLink,
  AddFolder,
  EditFolder,
  None,
}

enum RepositoryStatus {
  ShowFolders,
  UnknownRepository,
  PrivateRepository,
  ShowSearchResults,
}

export default function Repository() {
  const [showMoveDialog, setShowMoveDialog] = React.useState(false);
  const [editLink, setEditLink] = React.useState<ILink | null>(null);
  const [editFolder, setEditFolder] = React.useState<IFolder | null>(null);
  const [moveNode, setMoveNode] = React.useState<INode | null>(null);
  const [owner, setOwner] = React.useState<string>("");
  const [userIsOwner, setUserIsOwner] = React.useState<boolean>(false);
  const [nodes, setNodes] = React.useState<INode[]>([]);
  const [status, setStatus] = React.useState<RepositoryStatus>(
    RepositoryStatus.ShowFolders
  );
  const [parentFolderType, setParentFolderType] =
    React.useState<string>("root");
  const globalContext: IGlobalContext = React.useContext(GlobalContext);
  const navigate = useNavigate();
  const location = useLocation();

  const { search } = globalContext.state;

  const [showDialog, setShowDialog] = React.useState<ShowDialogType>(
    ShowDialogType.None
  );

  const closeDialog = () => {
    setEditFolder(null);
    setEditLink(null);
    setMoveNode(null);
    setShowDialog(ShowDialogType.None);
  };

  const onAddLinkClick = () => {
    setShowDialog(ShowDialogType.AddLink);
  };

  const onAddFolderClick = () => {
    setShowDialog(ShowDialogType.AddFolder);
  };

  const onMoveNodeClick = (node: INode) => {
    setMoveNode(node);
    setShowDialog(ShowDialogType.MoveNode);
  };

  const onEditNodeClick = (node: INode) => {
    if (node.type === "link") {
      setEditLink(node as ILink);
      setShowDialog(ShowDialogType.EditLink);
    } else {
      setEditFolder(node as IFolder);
      setShowDialog(ShowDialogType.EditFolder);
    }
  };

  const loadFolder = async (path: string) => {
    q(`loading repository ...`);

    try {
      const result = await fetch(lfCfg.backend + path, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = await result.json();
      if (data.errorId === 0) {
        q(`loaded repository: ${JSON.stringify(data)}`);
        setOwner(data.owner); // for OGG
        // when the repo turns out to be a link, redirect to the url
        if (data.type === "link") {
          window.location.href = data.url;
        }

        setParentFolderType(data.foldertype);

        if (
          globalContext.state.user &&
          data.owner === globalContext.state.user.username
        ) {
          setUserIsOwner(true);
        }

        globalContext.dispatch({
          type: "SET_APP_TITLE",
          value: path,
        });
        setStatus(RepositoryStatus.ShowFolders);
        setNodes(data.subnodes);
      } else if (data.errorId === 8011 || data.errorId === 1094) {
        setNodes([]);
        setStatus(RepositoryStatus.UnknownRepository);
      } else if (data.errorId === 8012) {
        setNodes([]);
        setStatus(RepositoryStatus.PrivateRepository);
      }
    } catch (e) {
      q(`error loading repository: ${e}`);
    }
  };

  const clickNode = (node: INode, popUp: boolean) => {
    if (node.type === "folder") {
      navigate(node.path);
    } else {
      node = node as ILink;

      // todo, add check if player is enabled
      if (popUp || !node.embedcode) {
        window.open(node.url);
        return;
      }

      let playable = false;
      // if the user is loggedin, he can save what domain will be played and what not (in settings menu)
      // if he is not logged in, every playable domain will be played
      if (
        globalContext.state.user !== null &&
        globalContext.state.user.playerSettings !== null
      ) {
        // check if the domain is in the list of played domains
        for (let playerSetting of globalContext.state.user.playerSettings) {
          if (
            playerSetting.domain === node.domainname &&
            playerSetting.enabled
          ) {
            playable = true;
            break;
          }
        }
      } else {
        playable = true;
      }

      if (playable && node.embedcode) {
        globalContext.dispatch({
          type: "SET_PLAYER",
          value: {
            html: node.embedcode
              .replace("%LBXWIDTH", "500")
              .replace("%LBXHEIGHT", "300"),
            width: 492,
            height: 340,
            minimized: false,
          },
        });
      } else {
        window.open(node.url);
        return;
      }
    }
  };

  const deleteNode = async (node: INode) => {
    try {
      const result = await fetch(lfCfg.backend + node.path, {
        method: "DELETE",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = await result.json();
      if (data.errorId === 0) {
        q(`deleted node: ${JSON.stringify(data)}`);
        setNodes(nodes.filter((n) => n.path !== node.path));
      } else {
        q(`could not delete node: ${JSON.stringify(data)}`);
      }
    } catch (e) {
      q(`could not delete node: ${JSON.stringify(e)}`);
    }
  };

  const handleAddLink = (newLink: ILink) => {
    setNodes([...nodes, newLink]);
    setShowDialog(ShowDialogType.None);
  };

  const handleAddFolder = (newFolder: IFolder) => {
    setNodes([...nodes, newFolder]);
    setShowDialog(ShowDialogType.None);
  };

  const handleSaveFolder = (saveFolder: IFolder) => {
    if (!saveFolder) return null;
    const pathArray = saveFolder.path.split("/");
    pathArray.pop(); // remove the folder's name
    const newPath = pathArray.join("/") + "/" + saveFolder.name;
    setNodes(
      nodes.map((node) =>
        node.path === saveFolder.path
          ? {
              ...saveFolder,
              path: newPath,
            }
          : node
      )
    );
    setShowDialog(ShowDialogType.None);
  };

  const handleSaveLink = (saveLink: ILink) => {
    setNodes(
      nodes.map((node) =>
        node.path === saveLink.path ? { ...saveLink } : node
      )
    );
    setShowDialog(ShowDialogType.None);
  };

  const handleMoveNode = (movingNode: ITreeNode) => {
    setNodes(nodes.filter((n) => n.path !== (movingNode as INode).path));
    setShowDialog(ShowDialogType.None);
  };

  React.useEffect(() => {
    q(`changing location to ${location.pathname}`);
    loadFolder(location.pathname);
  }, [location]);

  const imageOnErrorHandler = (
    event: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    event.currentTarget.src = `${lfCfg.frontend}/favicons/www.redmine.org.png`;
    event.currentTarget.className = "error";
  };

  const renderRepoException = (_status: RepositoryStatus) => {
    let icon: React.ReactNode | null = null;
    let text: string | null = null;

    if (_status === RepositoryStatus.ShowFolders) {
      icon = <FindInPageIcon />;
      text = "This linkbox is empty.";
    } else if (_status === RepositoryStatus.ShowSearchResults) {
      icon = <SearchIcon />;
      text = "Nothing found that matches the search.";
    } else if (_status === RepositoryStatus.UnknownRepository) {
      icon = <QuestionMarkIcon />;
      text = "Unknown repository.";
    } else if (_status === RepositoryStatus.PrivateRepository) {
      icon = <EnhancedEncryptionIcon />;
      text = "Private repository.";
    }

    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: 400,
        }}
      >
        {icon}
        <Typography variant="h6">{text}</Typography>
      </Box>
    );
  };

  const renderNodes = (_status: RepositoryStatus) => {
    if (nodes && nodes.length > 0) {
      return nodes.map((node: INode) => (
        <Box
          sx={{
            display: "flex",
            height: 32,
            alignItems: "center",
            cursor: "pointer",
            borderBottom: "0.5px solid",
            borderBottomColor: "rgb(230, 230, 230)",
          }}
          className="repo-node"
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => clickNode(node, false)}
          >
            {node.type === "link" && (
              <img
                src={`${lfCfg.frontend}/${lfCfg.favIconsFolderName}/${node.domain}.png`}
                style={{
                  display: "block",
                  width: 20,
                  height: 20,
                  marginTop: 2,
                }}
                onError={imageOnErrorHandler}
              />
            )}
            {node.type === "folder" && (
              <FolderIcon
                color={
                  (node as IFolder).foldertype === "public"
                    ? "primary"
                    : "secondary"
                }
              />
            )}
          </Box>
          <Box
            sx={{
              marginLeft: 1,
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              flex: 1,
            }}
            onClick={() => clickNode(node, false)}
          >
            {node.type === "link" ? (node as ILink).title : node.name}
          </Box>
          <Box
            sx={{
              marginLeft: "auto",
              minWidth: 108,
              display: "flex",
              justifyContent: "right",
            }}
          >
            <LinkIcon onClick={() => clickNode(node, true)} />
            {userIsOwner ? (
              <>
                <MoveUpIcon
                  onClick={() => onMoveNodeClick(node)}
                  style={{ marginLeft: 4 }}
                />
                <EditIcon
                  onClick={() => onEditNodeClick(node)}
                  style={{ marginLeft: 4 }}
                />
                <DeleteForeverIcon
                  style={{ marginLeft: 4 }}
                  onClick={() => deleteNode(node)}
                />
              </>
            ) : null}
          </Box>
        </Box>
      ));
    } else {
      return renderRepoException(_status);
    }
  };

  const clickSearchResult = (node: ISearchResult, popUp: boolean) => {
    if (node.type === "folder") {
      navigate(node.path);
    } else {
      // todo, add check if player is enabled
      if (popUp || !node.embedcode) {
        window.open(node.url);
        return;
      }

      let playable = false;
      // if the user is loggedin, he can save what domain will be played and what not (in settings menu)
      // if he is not logged in, every playable domain will be played
      if (
        globalContext.state.user !== null &&
        globalContext.state.user.playerSettings !== null
      ) {
        // check if the domain is in the list of played domains
        for (let playerSetting of globalContext.state.user.playerSettings) {
          if (
            playerSetting.domain === node.domainname &&
            playerSetting.enabled
          ) {
            playable = true;
            break;
          }
        }
      } else {
        playable = true;
      }

      if (playable && node.embedcode) {
        globalContext.dispatch({
          type: "SET_PLAYER",
          value: {
            html: node.embedcode
              .replace("%LBXWIDTH", "500")
              .replace("%LBXHEIGHT", "300"),
            width: 492,
            height: 340,
            minimized: false,
          },
        });
      } else {
        window.open(node.url);
        return;
      }
    }
  };

  const showSearchResults = () => {
    if (search.searchResults.length > 0) {
      return search.searchResults.map((searchResult: ISearchResult) => (
        <Box
          className="repo-node"
          sx={{
            display: "flex",
            height: 32,
            alignItems: "center",
            cursor: "pointer",
            borderBottom: "0.5px solid",
            borderBottomColor: "rgb(230, 230, 230)",
          }}
          onClick={() => clickSearchResult(searchResult, false)}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {searchResult.type === SearchResultType.Link ? (
              <img
                src={`${lfCfg.frontend}/${lfCfg.favIconsFolderName}/${searchResult.domain}.png`}
                style={{
                  display: "block",
                  width: 20,
                  height: 20,
                  marginTop: 2,
                }}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = `${lfCfg.frontend}/default_domain.png`;
                }}
              />
            ) : (
              <FolderIcon />
            )}
          </Box>
          <Box
            sx={{
              marginLeft: 1,
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            {searchResult.name}
          </Box>
          <Box sx={{ marginLeft: "auto" }}>{searchResult.path}</Box>
        </Box>
      ));
    }

    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: 400,
        }}
      >
        <SearchIcon />
        <Typography variant="h6">No results.</Typography>
      </Box>
    );
  };

  return (
    <Box>
      {globalContext.state.search.searchResults.length === 0 &&
      globalContext.state.search.searchQuery.length === 0
        ? renderNodes(status)
        : showSearchResults()}
      {userIsOwner ? (
        <RepoFAB
          handleAddLinkClick={onAddLinkClick}
          handleAddFolderClick={onAddFolderClick}
        />
      ) : null}
      <MoveNodeDialog
        open={showDialog === ShowDialogType.MoveNode}
        onCloseHandler={closeDialog}
        onMoveNode={handleMoveNode}
        movingNode={moveNode}
      />
      <AddOrEditLinkDialog
        open={
          showDialog === ShowDialogType.AddLink ||
          showDialog === ShowDialogType.EditLink
        }
        editLink={showDialog === ShowDialogType.EditLink ? editLink : null}
        onAddLink={handleAddLink}
        onCloseHandler={closeDialog}
        onSaveLink={handleSaveLink}
      />
      <AddOrEditFolderDialog
        open={
          showDialog === ShowDialogType.AddFolder ||
          showDialog === ShowDialogType.EditFolder
        }
        editFolder={
          showDialog === ShowDialogType.EditFolder ? editFolder : null
        }
        parentFolderType={parentFolderType}
        onCloseHandler={closeDialog}
        onAddFolder={handleAddFolder}
        onSaveFolder={handleSaveFolder}
      />
      <Box sx={{ height: 56 + 2 * 16 }} />
    </Box>
  );
}
