import dateFormat from "dateformat";
// keep in sync with server/validator.js

export const timeStamp = () => {
  const now = new Date();
  return dateFormat(now, "dd-mm-yyyy-HH:MM:ss:l-dddd");
};

export const q = (p: string) => {
  console.log("[lbx-frontend] " + timeStamp() + " - " + p);
};

export const arraySortByKey = (
  array: Record<string, string>[],
  key: keyof Record<string, string>,
  ascending: boolean
) => {
  return array.sort(function (a, b) {
    const x = Number.isFinite(a[key]) ? a[key] : a[key].toLowerCase();
    const y = Number.isFinite(b[key]) ? b[key] : b[key].toLowerCase();

    if (ascending) return x < y ? -1 : x > y ? 1 : 0;
    else return x > y ? -1 : x < y ? 1 : 0;
  });
};

export interface IValidationResult {
  errorId: number;
  message?: string;
}

export const isValidMinMaxString = (
  minChars: number,
  maxChars: number,
  str: string,
  strName: string
): IValidationResult => {
  if (str.length < minChars) {
    return {
      errorId: 5001,
      message: `${strName} minimum ${minChars} characters.`,
    };
  }

  if (str.length > maxChars) {
    return {
      errorId: 5002,
      message: `${strName} maximum ${maxChars} characters.`,
    };
  }

  return {
    errorId: 0,
    message: "valid",
  };
};

export const isValidPassword = (s: string): IValidationResult =>
  isValidMinMaxString(8, 100, s, "Password");

export const isValidEmail = (email: string): IValidationResult => {
  const valid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  if (valid) {
    return { errorId: 0, message: "Email valid" };
  } else {
    return { errorId: 5003, message: "Email invalid." };
  }
};

export const isValidUsername = (username: string): IValidationResult => {
  if (username.startsWith("_")) {
    return { errorId: 5012, message: "Username must not start with lowdash _" };
  }

  if (username.length < 3) {
    return {
      errorId: 5007,
      message: "Username too short, at least 3 characters.",
    };
  }

  if (username.length > 16) {
    return {
      errorId: 5008,
      message: "Username too long, maximum 16 characters.",
    };
  }

  const illegalCharacters = [" ", "/", "?", ",", ";", ".", ":", "&"];
  for (let i = 0; i < illegalCharacters.length; i++) {
    if (username.indexOf(illegalCharacters[i]) !== -1) {
      return {
        errorId: 5009,
        message:
          "Following characters are forbidden: (empty space), " +
          illegalCharacters.join(" "),
      };
    }
  }

  return { errorId: 0 };
};

export const isValidLinkTitle = (title: string): IValidationResult => {
  if (title.length < 1) {
    return { errorId: 5005, message: "Too short, at least one character." };
  }

  if (title.length > 120) {
    return { errorId: 5006, message: "Too long, maximum 120 characters." };
  }

  return { errorId: 0 };
};

export const isValidFolderPassword = (password: string): IValidationResult => {
  if (password.length < 4) {
    return {
      errorId: 5003,
      message: "Folder password too short, at least 4 characters.",
    };
  }

  if (password.length > 12) {
    return {
      errorId: 5004,
      message: "Folder password too long, maximum 12 characters.",
    };
  }

  return { errorId: 0 };
};

export const isValidFolderName = (foldername: string): IValidationResult => {
  if (foldername.length == 0) {
    return {
      errorId: 5000,
      message: "Foldername too short, at least 1 character.",
    };
  }

  if (foldername.length > 16) {
    return {
      errorId: 5001,
      message: "Foldername too long, maximum 16 characters.",
    };
  }

  if (foldername.startsWith("_")) {
    return {
      errorId: 5003,
      message: "Foldername must not start with _",
    };
  }

  const illegalCharacters = [" ", "/", "?", ",", ";", ".", ":", "&"];
  for (let i = 0; i < illegalCharacters.length; i++) {
    if (foldername.indexOf(illegalCharacters[i]) !== -1) {
      return {
        errorId: 5002,
        message:
          "In the foldername, following characters are forbidden: (empty space), " +
          illegalCharacters.join(" "),
      };
    }
  }

  return { errorId: 0 };
};

export const isValidFolderDescription = (
  description: string
): IValidationResult => {
  if (description.length > 32)
    return { errorId: 5049, message: "Description is too short" };

  var r = /^[a-zA-Z0-9\s!@#\$%\^\&*\)\(+=._-]+$/g;
  if (!description.match(r))
    return { errorId: 5039, message: "Invalid characters" };

  return { errorId: 0 };
};

export const isValidLinkDescription = (
  description: string
): IValidationResult => {
  if (description.length > 128)
    return { errorId: 5039, message: "Link description is too long." };

  var r = /^[a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]+$/g;
  if (!description.match(r))
    return { errorId: 5041, message: "Link description is too long." };

  return { errorId: 0 };
};
