import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import General from "./MyProfile/General";
import Account from "./MyProfile/Account";
import Players from "./MyProfile/Players";
import { GlobalContext, IGlobalContext } from "../GlobalContextProvider";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  isSmallMedia: boolean;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, isSmallMedia } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      style={{
        marginLeft: isSmallMedia ? 0 : 120,
        marginTop: 40,
      }}
    >
      {value === index && children}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function MyProfile() {
  const [value, setValue] = React.useState(0);
  const globalContext: IGlobalContext = React.useContext(GlobalContext);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Grid container spacing={2} style={{ paddingTop: 20, paddingBottom: 20 }}>
      <Grid item xs={0} md={2}></Grid>
      <Grid item xs={12} md={8}>
        <Box
          sx={{
            flexGrow: 1,
            bgcolor: "background.paper",
            justifyContent: "center",
            display: globalContext.state.isSmallMedia ? "block" : "flex",
            position: "relative",
            left: globalContext.state.isSmallMedia ? 0 : "-105px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Tabs
              orientation={
                globalContext.state.isSmallMedia ? "horizontal" : "vertical"
              }
              variant="scrollable"
              value={value}
              onChange={handleChange}
              aria-label="Vertical tabs example"
              sx={{
                borderRight: 1,
                borderColor: "divider",
              }}
            >
              <Tab label="General" {...a11yProps(0)} />
              <Tab label="Players" {...a11yProps(1)} />
              <Tab label="Account" {...a11yProps(2)} />
            </Tabs>
          </Box>
          <TabPanel
            value={value}
            index={0}
            isSmallMedia={globalContext.state.isSmallMedia}
          >
            <General />
          </TabPanel>
          <TabPanel
            value={value}
            index={1}
            isSmallMedia={globalContext.state.isSmallMedia}
          >
            <Players />
          </TabPanel>
          <TabPanel
            value={value}
            index={2}
            isSmallMedia={globalContext.state.isSmallMedia}
          >
            <Account />
          </TabPanel>
        </Box>
      </Grid>
      <Grid item xs={0} md={2}></Grid>
    </Grid>
  );
}
