import React from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

function Imprint() {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div style={{ padding: 30, maxWidth: "90%" }}>
        <h1>Impressum</h1>
        <p>Informationspflicht laut § 5 TMG.</p>
        <p>
          linkbox.io ist eine Marke der Firma cileria UG
          <br />
          Koppenstr. 28, <br />
          10243 Berlin, <br />
          Deutschland
        </p>
        <p></p>
        <p>
          <strong>E-Mail:</strong>{" "}
          <a href="mailto:jan.schulz@cileria.com">jan.schulz@cileria.com</a>
        </p>
        <p>
          <strong>Berufsbezeichnung:</strong> Webentwickler & Dozent
        </p>
        <h2>EU-Streitschlichtung</h2>
        <p>
          Gemäß Verordnung über Online-Streitbeilegung in
          Verbraucherangelegenheiten (ODR-Verordnung) möchten wir Sie über die
          Online-Streitbeilegungsplattform (OS-Plattform) informieren.
          <br />
          Verbraucher haben die Möglichkeit, Beschwerden an die Online
          Streitbeilegungsplattform der Europäischen Kommission unter{" "}
          <a
            href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&amp;lng=DE"
            target="_blank"
            rel="noopener"
          >
            http://ec.europa.eu/odr?tid=321234633
          </a>{" "}
          zu richten. Die dafür notwendigen Kontaktdaten finden Sie oberhalb in
          unserem Impressum.
        </p>
        <p>
          Wir möchten Sie jedoch darauf hinweisen, dass wir nicht bereit oder
          verpflichtet sind, an Streitbeilegungsverfahren vor einer
          Verbraucherschlichtungsstelle teilzunehmen.
        </p>
        <h2>Haftung für Inhalte dieser Website</h2>
        <p>
          Wir entwickeln die Inhalte dieser Webseite ständig weiter und bemühen
          uns korrekte und aktuelle Informationen bereitzustellen. Laut
          Telemediengesetz{" "}
          <a
            href="https://www.gesetze-im-internet.de/tmg/__7.html?tid=321234633"
            rel="noopener"
            target="_blank"
          >
            (TMG) §7 (1)
          </a>{" "}
          sind wir als Diensteanbieter für eigene Informationen, die wir zur
          Nutzung bereitstellen, nach den allgemeinen Gesetzen verantwortlich.
          Leider können wir keine Haftung für die Korrektheit aller Inhalte auf
          dieser Webseite übernehmen, speziell für jene die seitens Dritter
          bereitgestellt wurden. Als Diensteanbieter im Sinne der §§ 8 bis 10
          sind wir nicht verpflichtet, die von ihnen übermittelten oder
          gespeicherten Informationen zu überwachen oder nach Umständen zu
          forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
        </p>
        <p>
          Unsere Verpflichtungen zur Entfernung von Informationen oder zur
          Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen
          aufgrund von gerichtlichen oder behördlichen Anordnungen bleiben auch
          im Falle unserer Nichtverantwortlichkeit nach den §§ 8 bis 10
          unberührt.{" "}
        </p>
        <p>
          Sollten Ihnen problematische oder rechtswidrige Inhalte auffallen,
          bitte wir Sie uns umgehend zu kontaktieren, damit wir die
          rechtswidrigen Inhalte entfernen können. Sie finden die Kontaktdaten
          im Impressum.
        </p>
        <h2>Haftung für Links auf dieser Website</h2>
        <p>
          Unsere Webseite enthält Links zu anderen Webseiten für deren Inhalt
          wir nicht verantwortlich sind. Haftung für verlinkte Websites besteht
          für uns nicht, da wir keine Kenntnis rechtswidriger Tätigkeiten hatten
          und haben, uns solche Rechtswidrigkeiten auch bisher nicht aufgefallen
          sind und wir Links sofort entfernen würden, wenn uns
          Rechtswidrigkeiten bekannt werden.
        </p>
        <p>
          Wenn Ihnen rechtswidrige Links auf unserer Website auffallen, bitte
          wir Sie uns zu kontaktieren. Sie finden die Kontaktdaten im Impressum.
        </p>
        <h2>Urheberrechtshinweis</h2>
        <p>
          Alle Inhalte dieser Webseite (Bilder, Fotos, Texte, Videos)
          unterliegen dem Urheberrecht der Bundesrepublik Deutschland. Bitte
          fragen Sie uns bevor Sie die Inhalte dieser Website verbreiten,
          vervielfältigen oder verwerten wie zum Beispiel auf anderen Websites
          erneut veröffentlichen. Falls notwendig, werden wir die unerlaubte
          Nutzung von Teilen der Inhalte unserer Seite rechtlich verfolgen.
        </p>
        <p>
          Sollten Sie auf dieser Webseite Inhalte finden, die das Urheberrecht
          verletzen, bitten wir Sie uns zu kontaktieren.
        </p>
        <h2>Bildernachweis</h2>
        <p>
          Die Bilder, Fotos und Grafiken auf dieser Webseite sind
          urheberrechtlich geschützt.
        </p>
        <p>
          Die Bilderrechte liegen bei den folgenden Fotografen und Unternehmen:
        </p>
        <ul>
          <li>cileria UG</li>
        </ul>
        <h1>Datenschutzerklärung</h1>
        <h2>Datenschutz</h2>
        <p>
          Wir haben diese Datenschutzerklärung (Fassung 25.11.2020-321234633)
          verfasst, um Ihnen gemäß der Vorgaben der{" "}
          <a
            href="https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679&amp;tid=321234633"
            target="_blank"
            rel="noopener"
          >
            Datenschutz-Grundverordnung (EU) 2016/679
          </a>{" "}
          zu erklären, welche Informationen wir sammeln, wie wir Daten verwenden
          und welche Entscheidungsmöglichkeiten Sie als Besucher dieser Webseite
          haben.
        </p>
        <p>
          Leider liegt es in der Natur der Sache, dass diese Erklärungen sehr
          technisch klingen, wir haben uns bei der Erstellung jedoch bemüht die
          wichtigsten Dinge so einfach und klar wie möglich zu beschreiben.
        </p>
        <h2>Automatische Datenspeicherung</h2>
        <p>
          Wenn Sie heutzutage Webseiten besuchen, werden gewisse Informationen
          automatisch erstellt und gespeichert, so auch auf dieser Webseite.
        </p>
        <p>
          Wenn Sie unsere Webseite so wie jetzt gerade besuchen, speichert unser
          Webserver (Computer auf dem diese Webseite gespeichert ist)
          automatisch Daten wie
        </p>
        <ul>
          <li>die Adresse (URL) der aufgerufenen Webseite</li>
          <li>Browser und Browserversion</li>
          <li>das verwendete Betriebssystem</li>
          <li>die Adresse (URL) der zuvor besuchten Seite (Referrer URL)</li>
          <li>
            den Hostname und die IP-Adresse des Geräts von welchem aus
            zugegriffen wird
          </li>
          <li>Datum und Uhrzeit</li>
        </ul>
        <p>in Dateien (Webserver-Logfiles).</p>
        <p>
          In der Regel werden Webserver-Logfiles zwei Wochen gespeichert und
          danach automatisch gelöscht. Wir geben diese Daten nicht weiter,
          können jedoch nicht ausschließen, dass diese Daten beim Vorliegen von
          rechtswidrigem Verhalten eingesehen werden.
        </p>
        <h2>Cookies</h2>
        <p>
          Unsere Website verwendet HTTP-Cookies um nutzerspezifische Daten zu
          speichern.
          <br />
          Im Folgenden erklären wir, was Cookies sind und warum Sie genutzt
          werden, damit Sie die folgende Datenschutzerklärung besser verstehen.
        </p>
        <h3>Was genau sind Cookies?</h3>
        <p>
          Immer wenn Sie durch das Internet surfen, verwenden Sie einen Browser.
          Bekannte Browser sind beispielsweise Chrome, Safari, Firefox, Internet
          Explorer und Microsoft Edge. Die meisten Webseiten speichern kleine
          Text-Dateien in Ihrem Browser. Diese Dateien nennt man Cookies.
        </p>
        <p>
          Eines ist nicht von der Hand zu weisen: Cookies sind echt nützliche
          Helferlein. Fast alle Webseiten verwenden Cookies. Genauer gesprochen
          sind es HTTP-Cookies, da es auch noch andere Cookies für andere
          Anwendungsbereiche gibt. HTTP-Cookies sind kleine Dateien, die von
          unserer Website auf Ihrem Computer gespeichert werden. Diese
          Cookie-Dateien werden automatisch im Cookie-Ordner, quasi dem
          &#8220;Hirn&#8221; Ihres Browsers, untergebracht. Ein Cookie besteht
          aus einem Namen und einem Wert. Bei der Definition eines Cookies
          müssen zusätzlich ein oder mehrere Attribute angegeben werden.
        </p>
        <p>
          Cookies speichern gewisse Nutzerdaten von Ihnen, wie beispielsweise
          Sprache oder persönliche Seiteneinstellungen. Wenn Sie unsere Seite
          wieder aufrufen, übermittelt Ihr Browser die „userbezogenen“
          Informationen an unsere Seite zurück. Dank der Cookies weiß unsere
          Website, wer Sie sind und bietet Ihnen Ihre gewohnte
          Standardeinstellung. In einigen Browsern hat jedes Cookie eine eigene
          Datei, in anderen wie beispielsweise Firefox sind alle Cookies in
          einer einzigen Datei gespeichert.
        </p>
        <p>
          Es gibt sowohl Erstanbieter Cookies als auch Drittanbieter-Cookies.
          Erstanbieter-Cookies werden direkt von unserer Seite erstellt,
          Drittanbieter-Cookies werden von Partner-Webseiten (z.B. Google
          Analytics) erstellt. Jedes Cookie ist individuell zu bewerten, da
          jedes Cookie andere Daten speichert. Auch die Ablaufzeit eines Cookies
          variiert von ein paar Minuten bis hin zu ein paar Jahren. Cookies sind
          keine Software-Programme und enthalten keine Viren, Trojaner oder
          andere „Schädlinge“. Cookies können auch nicht auf Informationen Ihres
          PCs zugreifen.
        </p>
        <p>So können zum Beispiel Cookie-Daten aussehen:</p>
        <ul>
          <li>Name: _ga</li>
          <li>Ablaufzeit: 2 Jahre</li>
          <li>Verwendung: Unterscheidung der Webseitenbesucher</li>
          <li>Beispielhafter Wert: GA1.2.1326744211.152321234633</li>
        </ul>
        <p>Ein Browser sollte folgende Mindestgrößen unterstützen:</p>
        <ul>
          <li>Ein Cookie soll mindestens 4096 Bytes enthalten können</li>
          <li>
            Pro Domain sollen mindestens 50 Cookies gespeichert werden können
          </li>
          <li>
            Insgesamt sollen mindestens 3000 Cookies gespeichert werden können
          </li>
        </ul>
        <h3>Welche Arten von Cookies gibt es?</h3>
        <p>
          Die Frage welche Cookies wir im Speziellen verwenden, hängt von den
          verwendeten Diensten ab und wird in der folgenden Abschnitten der
          Datenschutzerklärung geklärt. An dieser Stelle möchten wir kurz auf
          die verschiedenen Arten von HTTP-Cookies eingehen.
        </p>
        <p>Man kann 4 Arten von Cookies unterscheiden:</p>
        <p>
          <strong>
            Unbedingt notwendige Cookies
            <br />
          </strong>
          Diese Cookies sind nötig, um grundlegende Funktionen der Website
          sicherzustellen. Zum Beispiel braucht es diese Cookies, wenn ein User
          ein Produkt in den Warenkorb legt, dann auf anderen Seiten weitersurft
          und später erst zur Kasse geht. Durch diese Cookies wird der Warenkorb
          nicht gelöscht, selbst wenn der User sein Browserfenster schließt.
        </p>
        <p>
          <strong>
            Funktionelle Cookies
            <br />
          </strong>
          Diese Cookies sammeln Infos über das Userverhalten und ob der User
          etwaige Fehlermeldungen bekommt. Zudem werden mithilfe dieser Cookies
          auch die Ladezeit und das Verhalten der Website bei verschiedenen
          Browsern gemessen.
        </p>
        <p>
          <strong>
            Zielorientierte Cookies
            <br />
          </strong>
          Diese Cookies sorgen für eine bessere Nutzerfreundlichkeit.
          Beispielsweise werden eingegebene Standorte, Schriftgrößen oder
          Formulardaten gespeichert.
        </p>
        <p>
          <strong>
            Werbe-Cookies
            <br />
          </strong>
          Diese Cookies werden auch Targeting-Cookies genannt. Sie dienen dazu
          dem User individuell angepasste Werbung zu liefern. Das kann sehr
          praktisch, aber auch sehr nervig sein.
        </p>
        <p>
          Üblicherweise werden Sie beim erstmaligen Besuch einer Webseite
          gefragt, welche dieser Cookiearten Sie zulassen möchten. Und natürlich
          wird diese Entscheidung auch in einem Cookie gespeichert.
        </p>
        <h3>Wie kann ich Cookies löschen?</h3>
        <p>
          Wie und ob Sie Cookies verwenden wollen, entscheiden Sie selbst.
          Unabhängig von welchem Service oder welcher Website die Cookies
          stammen, haben Sie immer die Möglichkeit Cookies zu löschen, nur
          teilweise zuzulassen oder zu deaktivieren. Zum Beispiel können Sie
          Cookies von Drittanbietern blockieren, aber alle anderen Cookies
          zulassen.
        </p>
        <p>
          Wenn Sie feststellen möchten, welche Cookies in Ihrem Browser
          gespeichert wurden, wenn Sie Cookie-Einstellungen ändern oder löschen
          wollen, können Sie dies in Ihren Browser-Einstellungen finden:
        </p>
        <p>
          <a
            href="https://support.google.com/chrome/answer/95647?tid=321234633"
            target="_blank"
            rel="noopener"
          >
            Chrome: Cookies in Chrome löschen, aktivieren und verwalten
          </a>
        </p>
        <p>
          <a
            href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=321234633"
            target="_blank"
            rel="noopener"
          >
            Safari: Verwalten von Cookies und Websitedaten mit Safari
          </a>
        </p>
        <p>
          <a
            href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=321234633"
            target="_blank"
            rel="noopener"
          >
            Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf
            Ihrem Computer abgelegt haben
          </a>
        </p>
        <p>
          <a
            href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=321234633"
            target="_blank"
            rel="noopener"
          >
            Internet Explorer: Löschen und Verwalten von Cookies
          </a>
        </p>
        <p>
          <a
            href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=321234633"
            target="_blank"
            rel="noopener"
          >
            Microsoft Edge: Löschen und Verwalten von Cookies
          </a>
        </p>
        <p>
          Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren
          Browser so einrichten, dass er Sie immer informiert, wenn ein Cookie
          gesetzt werden soll. So können Sie bei jedem einzelnen Cookie
          entscheiden, ob Sie das Cookie erlauben oder nicht. Die Vorgangsweise
          ist je nach Browser verschieden. Am besten ist es Sie suchen die
          Anleitung in Google mit dem Suchbegriff “Cookies löschen Chrome” oder
          &#8220;Cookies deaktivieren Chrome&#8221; im Falle eines Chrome
          Browsers oder tauschen das Wort &#8220;Chrome&#8221; gegen den Namen
          Ihres Browsers, z.B. Edge, Firefox, Safari aus.
        </p>
        <h3>Wie sieht es mit meinem Datenschutz aus?</h3>
        <p>
          Seit 2009 gibt es die sogenannten „Cookie-Richtlinien“. Darin ist
          festgehalten, dass das Speichern von Cookies eine Einwilligung von
          Ihnen verlangt. Innerhalb der EU-Länder gibt es allerdings noch sehr
          unterschiedliche Reaktionen auf diese Richtlinien. In Deutschland
          wurden die Cookie-Richtlinien nicht als nationales Recht umgesetzt.
          Stattdessen erfolgte die Umsetzung dieser Richtlinie weitgehend in §
          15 Abs.3 des Telemediengesetzes (TMG).
        </p>
        <p>
          Wenn Sie mehr über Cookies wissen möchten und technischen
          Dokumentationen nicht scheuen, empfehlen wir 
          <a
            href="https://tools.ietf.org/html/rfc6265"
            target="_blank"
            rel="nofollow noopener"
          >
            https://tools.ietf.org/html/rfc6265
          </a>
          , dem Request for Comments der Internet Engineering Task Force (IETF)
          namens &#8220;HTTP State Management Mechanism&#8221;.
        </p>
        <h2>Speicherung persönlicher Daten</h2>
        <p>
          Persönliche Daten, die Sie uns auf dieser Website elektronisch
          übermitteln, wie zum Beispiel Name, E-Mail-Adresse, Adresse oder
          andere persönlichen Angaben im Rahmen der Übermittlung eines Formulars
          oder Kommentaren im Blog, werden von uns gemeinsam mit dem Zeitpunkt
          und der IP-Adresse nur zum jeweils angegebenen Zweck verwendet, sicher
          verwahrt und nicht an Dritte weitergegeben.
        </p>
        <p>
          Wir nutzen Ihre persönlichen Daten somit nur für die Kommunikation mit
          jenen Besuchern, die Kontakt ausdrücklich wünschen und für die
          Abwicklung der auf dieser Webseite angebotenen Dienstleistungen und
          Produkte. Wir geben Ihre persönlichen Daten ohne Zustimmung nicht
          weiter, können jedoch nicht ausschließen, dass diese Daten beim
          Vorliegen von rechtswidrigem Verhalten eingesehen werden.
        </p>
        <p>
          Wenn Sie uns persönliche Daten per E-Mail schicken &#8211; somit
          abseits dieser Webseite &#8211; können wir keine sichere Übertragung
          und den Schutz Ihrer Daten garantieren. Wir empfehlen Ihnen,
          vertrauliche Daten niemals unverschlüsselt per E-Mail zu übermitteln.
        </p>
        <p>
          Die Rechtsgrundlage besteht nach{" "}
          <a
            href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&amp;from=DE&amp;tid=321234633"
            target="_blank"
            rel="noopener"
          >
            Artikel 6  Absatz 1 a DSGVO
          </a>{" "}
          (Rechtmäßigkeit der Verarbeitung) darin, dass Sie uns die Einwilligung
          zur Verarbeitung der von Ihnen eingegebenen Daten geben. Sie können
          diesen Einwilligung jederzeit widerrufen &#8211; eine formlose E-Mail
          reicht aus, Sie finden unsere Kontaktdaten im Impressum.
        </p>
        <h2>Rechte laut Datenschutzgrundverordnung</h2>
        <p>
          Ihnen stehen laut den Bestimmungen der DSGVO grundsätzlich die
          folgende Rechte zu:
        </p>
        <ul>
          <li>Recht auf Berichtigung (Artikel 16 DSGVO)</li>
          <li>
            Recht auf Löschung („Recht auf Vergessenwerden“) (Artikel 17 DSGVO)
          </li>
          <li>Recht auf Einschränkung der Verarbeitung (Artikel 18 DSGVO)</li>
          <li>
            Recht auf Benachrichtigung &#8211; Mitteilungspflicht im
            Zusammenhang mit der Berichtigung oder Löschung personenbezogener
            Daten oder der Einschränkung der Verarbeitung (Artikel 19 DSGVO)
          </li>
          <li>Recht auf Datenübertragbarkeit (Artikel 20 DSGVO)</li>
          <li>Widerspruchsrecht (Artikel 21 DSGVO)</li>
          <li>
            Recht, nicht einer ausschließlich auf einer automatisierten
            Verarbeitung — einschließlich Profiling — beruhenden Entscheidung
            unterworfen zu werden (Artikel 22 DSGVO)
          </li>
        </ul>
        <p>
          Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das
          Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche
          sonst in einer Weise verletzt worden sind, können Sie sich an die{" "}
          <a href="https://www.bfdi.bund.de" target="_blank" rel="noopener">
            Bundesbeauftragte für den Datenschutz und die Informationsfreiheit
            (BfDI)
          </a>{" "}
          wenden.
        </p>
        <h2>Auswertung des Besucherverhaltens</h2>
        <p>
          In der folgenden Datenschutzerklärung informieren wir Sie darüber, ob
          und wie wir Daten Ihres Besuchs dieser Website auswerten. Die
          Auswertung der gesammelten Daten erfolgt in der Regel anonym und wir
          können von Ihrem Verhalten auf dieser Website nicht auf Ihre Person
          schließen.
        </p>
        <p>
          Mehr über Möglichkeiten dieser Auswertung der Besuchsdaten zu
          widersprechen erfahren Sie in der folgenden Datenschutzerklärung.
        </p>
        <h2>TLS-Verschlüsselung mit https</h2>
        <p>
          Wir verwenden https um Daten abhörsicher im Internet zu übertragen
          (Datenschutz durch Technikgestaltung{" "}
          <a
            href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&amp;from=DE&amp;tid=321234633"
            target="_blank"
            rel="noopener"
          >
            Artikel 25 Absatz 1 DSGVO
          </a>
          ). Durch den Einsatz von TLS (Transport Layer Security), einem
          Verschlüsselungsprotokoll zur sicheren Datenübertragung im Internet
          können wir den Schutz vertraulicher Daten sicherstellen. Sie erkennen
          die Benutzung dieser Absicherung der Datenübertragung am kleinen
          Schloßsymbol links oben im Browser und der Verwendung des Schemas
          https (anstatt http) als Teil unserer Internetadresse.
        </p>
        <h2>Google Analytics Datenschutzerklärung</h2>
        <p>
          Wir verwenden auf unserer Website das Analyse-Tracking Tool Google
          Analytics (GA) des amerikanischen Unternehmens Google Inc. Für den
          europäischen Raum ist das Unternehmen Google Ireland Limited (Gordon
          House, Barrow Street Dublin 4, Irland) für alle Google-Dienste
          verantwortlich. Google Analytics sammelt Daten über Ihre Handlungen
          auf unserer Website. Wenn Sie beispielsweise einen Link anklicken,
          wird diese Aktion in einem Cookie gespeichert und an Google Analytics
          versandt. Mithilfe der Berichte, die wir von Google Analytics
          erhalten, können wir unsere Website und unser Service besser an Ihre
          Wünsche anpassen. Im Folgenden gehen wir näher auf das Tracking Tool
          ein und informieren Sie vor allem darüber, welche Daten gespeichert
          werden und wie Sie das verhindern können.
        </p>
        <h3>Was ist Google Analytics?</h3>
        <p>
          Google Analytics ist ein Trackingtool, das der Datenverkehrsanalyse
          unserer Website dient. Damit Google Analytics funktioniert, wird ein
          Tracking-Code in den Code unserer Website eingebaut. Wenn Sie unsere
          Website besuchen, zeichnet dieser Code verschiedene Handlungen auf,
          die Sie auf unserer Website ausführen. Sobald Sie unsere Website
          verlassen, werden diese Daten an die Google-Analytics-Server gesendet
          und dort gespeichert.
        </p>
        <p>
          Google verarbeitet die Daten und wir bekommen Berichte über Ihr
          Userverhalten. Dabei kann es sich unter anderem um folgende Berichte
          handeln:
        </p>
        <ul>
          <li>
            Zielgruppenberichte: Über Zielgruppenberichte lernen wir unsere User
            besser kennen und wissen genauer, wer sich für unser Service
            interessiert.
          </li>
          <li>
            Anzeigeberichte: Durch Anzeigeberichte können wir unsere
            Onlinewerbung leichter analysieren und verbessern.
          </li>
          <li>
            Akquisitionsberichte: Akquisitionsberichte geben uns hilfreiche
            Informationen darüber, wie wir mehr Menschen für unser Service
            begeistern können.
          </li>
          <li>
            Verhaltensberichte: Hier erfahren wir, wie Sie mit unserer Website
            interagieren. Wir können nachvollziehen welchen Weg Sie auf unserer
            Seite zurücklegen und welche Links Sie anklicken.
          </li>
          <li>
            Conversionsberichte: Conversion nennt man einen Vorgang, bei dem Sie
            aufgrund einer Marketing-Botschaft eine gewünschte Handlung
            ausführen. Zum Beispiel, wenn Sie von einem reinen Websitebesucher
            zu einem Käufer oder Newsletter-Abonnent werden. Mithilfe dieser
            Berichte erfahren wir mehr darüber, wie unsere Marketing-Maßnahmen
            bei Ihnen ankommen. So wollen wir unsere Conversionrate steigern.
          </li>
          <li>
            Echtzeitberichte: Hier erfahren wir immer sofort, was gerade auf
            unserer Website passiert. Zum Beispiel sehen wir wie viele User
            gerade diesen Text lesen.
          </li>
        </ul>
        <h3>Warum verwenden wir Google Analytics auf unserer Webseite?</h3>
        <p>
          Unser Ziel mit dieser Website ist klar: Wir wollen Ihnen das
          bestmögliche Service bieten. Die Statistiken und Daten von Google
          Analytics helfen uns dieses Ziel zu erreichen.
        </p>
        <p>
          Die statistisch ausgewerteten Daten zeigen uns ein klares Bild von den
          Stärken und Schwächen unserer Website. Einerseits können wir unsere
          Seite so optimieren, dass sie von interessierten Menschen auf Google
          leichter gefunden wird. Andererseits helfen uns die Daten, Sie als
          Besucher besser zu verstehen. Wir wissen somit sehr genau, was wir an
          unserer Website verbessern müssen, um Ihnen das bestmögliche Service
          zu bieten. Die Daten dienen uns auch, unsere Werbe- und
          Marketing-Maßnahmen individueller und kostengünstiger durchzuführen.
          Schließlich macht es nur Sinn, unsere Produkte und Dienstleistungen
          Menschen zu zeigen, die sich dafür interessieren.
        </p>
        <h3>Welche Daten werden von Google Analytics gespeichert?</h3>
        <p>
          Google Analytics erstellt mithilfe eines Tracking-Codes eine
          zufällige, eindeutige ID, die mit Ihrem Browser-Cookie verbunden ist.
          So erkennt Sie Google Analytics als neuen User. Wenn Sie das nächste
          Mal unsere Seite besuchen, werden Sie als „wiederkehrender“ User
          erkannt. Alle gesammelten Daten werden gemeinsam mit dieser User-ID
          gespeichert. So ist es überhaupt erst möglich pseudonyme Userprofile
          auszuwerten.
        </p>
        <p>
          Durch Kennzeichnungen wie Cookies und App-Instanz-IDs werden Ihre
          Interaktionen auf unserer Website gemessen. Interaktionen sind alle
          Arten von Handlungen, die Sie auf unserer Website ausführen. Wenn Sie
          auch andere Google-Systeme (wie z.B. ein Google-Konto) nützen, können
          über Google Analytics generierte Daten mit Drittanbieter-Cookies
          verknüpft werden. Google gibt keine Google Analytics-Daten weiter,
          außer wir als Websitebetreiber genehmigen das. Zu Ausnahmen kann es
          kommen, wenn es gesetzlich erforderlich ist.
        </p>
        <p>Folgende Cookies werden von Google Analytics verwendet:</p>
        <p>
          <strong>Name:</strong> _ga
          <br />
          <strong>Wert: </strong>
          2.1326744211.152321234633-5
          <br />
          <strong>Verwendungszweck:</strong> Standardmäßig verwendet
          analytics.js das Cookie _ga, um die User-ID zu speichern.
          Grundsätzlich dient es zur Unterscheidung der Webseitenbesucher.
          <br />
          <strong>Ablaufdatum:</strong> nach 2 Jahren
        </p>
        <p>
          <strong>Name:</strong> _gid
          <br />
          <strong>Wert: </strong>
          2.1687193234.152321234633-1
          <br />
          <strong>Verwendungszweck:</strong> Das Cookie dient auch zur
          Unterscheidung der Webseitenbesucher.
          <br />
          <strong>Ablaufdatum:</strong> nach 24 Stunden
        </p>
        <p>
          <strong>Name:</strong> _gat_gtag_UA_&lt;property-id&gt;
          <br />
          <strong>Wert:</strong> 1<br />
          <strong>Verwendungszweck:</strong> Wird zum Senken der
          Anforderungsrate verwendet. Wenn Google Analytics über den Google Tag
          Manager bereitgestellt wird, erhält dieser Cookie den Namen _dc_gtm_
          &lt;property-id&gt;.
          <br />
          <strong>Ablaufdatum: </strong>nach 1 Minute
        </p>
        <p>
          <strong>Name:</strong> AMP_TOKEN
          <br />
          <strong>Wert:</strong> keine Angaben
          <br />
          <strong>Verwendungszweck:</strong> Das Cookie hat einen Token, mit dem
          eine User ID vom AMP-Client-ID-Dienst abgerufen werden kann. Andere
          mögliche Werte weisen auf eine Abmeldung, eine Anfrage oder einen
          Fehler hin.
          <br />
          <strong>Ablaufdatum:</strong> nach 30 Sekunden bis zu einem Jahr
        </p>
        <p>
          <strong>Name:</strong> __utma
          <br />
          <strong>Wert: </strong>
          1564498958.1564498958.1564498958.1
          <br />
          <strong>Verwendungszweck:</strong> Mit diesem Cookie kann man Ihr
          Verhalten auf der Website verfolgen und die Leistung messen. Das
          Cookie wird jedes Mal aktualisiert, wenn Informationen an Google
          Analytics gesendet werden.
          <br />
          <strong>Ablaufdatum:</strong> nach 2 Jahren
        </p>
        <p>
          <strong>Name:</strong> __utmt
          <br />
          <strong>Wert:</strong> 1<br />
          <strong>Verwendungszweck:</strong> Das Cookie wird wie
          _gat_gtag_UA_&lt;property-id&gt; zum Drosseln der Anforderungsrate
          verwendet.
          <br />
          <strong>Ablaufdatum:</strong> nach 10 Minuten
        </p>
        <p>
          <strong>Name:</strong> __utmb
          <br />
          <strong>Wert: </strong>3.10.1564498958
          <br />
          <strong>Verwendungszweck:</strong> Dieses Cookie wird verwendet, um
          neue Sitzungen zu bestimmen. Es wird jedes Mal aktualisiert, wenn neue
          Daten bzw. Infos an Google Analytics gesendet werden.
          <br />
          <strong>Ablaufdatum:</strong> nach 30 Minuten
        </p>
        <p>
          <strong>Name:</strong> __utmc
          <br />
          <strong>Wert:</strong> 167421564
          <br />
          <strong>Verwendungszweck:</strong> Dieses Cookie wird verwendet, um
          neue Sitzungen für wiederkehrende Besucher festzulegen. Dabei handelt
          es sich um ein Session-Cookie, und es wird nur solange gespeichert,
          bis Sie den Browser wieder schließen.
          <br />
          <strong>Ablaufdatum:</strong> Nach Schließung des Browsers
        </p>
        <p>
          <strong>Name:</strong> __utmz
          <br />
          <strong>Wert:</strong> m|utmccn=(referral)|utmcmd=referral|utmcct=/
          <br />
          <strong>Verwendungszweck:</strong> Das Cookie wird verwendet, um die
          Quelle des Besucheraufkommens auf unserer Website zu identifizieren.
          Das heißt, das Cookie speichert, von wo Sie auf unsere Website
          gekommen sind. Das kann eine andere Seite bzw. eine Werbeschaltung
          gewesen sein.
          <br />
          <strong>Ablaufdatum:</strong> nach 6 Monaten
        </p>
        <p>
          <strong>Name:</strong> __utmv
          <br />
          <strong>Wert:</strong> keine Angabe
          <br />
          <strong>Verwendungszweck:</strong> Das Cookie wird verwendet, um
          benutzerdefinierte Userdaten zu speichern. Es wird immer aktualisiert,
          wenn Informationen an Google Analytics gesendet werden.
          <br />
          <strong>Ablaufdatum:</strong> nach 2 Jahren
        </p>
        <p>
          <strong>Anmerkung:</strong> Diese Aufzählung kann keinen Anspruch auf
          Vollständigkeit erheben, da Google die Wahl seiner Cookies immer
          wieder verändert.
        </p>
        <p>
          Hier zeigen wir Ihnen einen Überblick über die wichtigsten Daten, die
          mit Google Analytics erhoben werden:
        </p>
        <p>
          <strong>Heatmaps:</strong> Google legt sogenannte Heatmaps an. Über
          Heatmaps sieht man genau jene Bereiche, die Sie anklicken. So bekommen
          wir Informationen, wo Sie auf unserer Seite „unterwegs“ sind.
        </p>
        <p>
          <strong>Sitzungsdauer:</strong> Als Sitzungsdauer bezeichnet Google
          die Zeit, die Sie auf unserer Seite verbringen, ohne die Seite zu
          verlassen. Wenn Sie 20 Minuten inaktiv waren, endet die Sitzung
          automatisch.
        </p>
        <p>
          <strong>Absprungrate</strong> (engl. Bouncerate): Von einem Absprung
          ist die Rede, wenn Sie auf unserer Website nur eine Seite ansehen und
          dann unsere Website wieder verlassen.
        </p>
        <p>
          <strong>Kontoerstellung:</strong> Wenn Sie auf unserer Website ein
          Konto erstellen bzw. eine Bestellung machen, erhebt Google Analytics
          diese Daten.
        </p>
        <p>
          <strong>IP-Adresse:</strong> Die IP-Adresse wird nur in gekürzter Form
          dargestellt, damit keine eindeutige Zuordnung möglich ist.
        </p>
        <p>
          <strong>Standort:</strong> Über die IP-Adresse kann das Land und Ihr
          ungefährer Standort bestimmt werden. Diesen Vorgang bezeichnet man
          auch als IP- Standortbestimmung.
        </p>
        <p>
          <strong>Technische Informationen:</strong> Zu den technischen
          Informationen zählen unter anderem Ihr Browsertyp, Ihr
          Internetanbieter oder Ihre Bildschirmauflösung.
        </p>
        <p>
          <strong>Herkunftsquelle:</strong> Google Analytics beziehungsweise
          uns, interessiert natürlich auch über welche Website oder welche
          Werbung Sie auf unsere Seite gekommen sind.
        </p>
        <p>
          Weitere Daten sind Kontaktdaten, etwaige Bewertungen, das Abspielen
          von Medien (z.B., wenn Sie ein Video über unsere Seite abspielen), das
          Teilen von Inhalten über Social Media oder das Hinzufügen zu Ihren
          Favoriten. Die Aufzählung hat keinen Vollständigkeitsanspruch und
          dient nur zu einer allgemeinen Orientierung der Datenspeicherung durch
          Google Analytics.
        </p>
        <h3>Wie lange und wo werden die Daten gespeichert?</h3>
        <p>
          Google hat Ihre Server auf der ganzen Welt verteilt. Die meisten
          Server befinden sich in Amerika und folglich werden Ihre Daten meist
          auf amerikanischen Servern gespeichert. Hier können Sie genau
          nachlesen wo sich die Google-Rechenzentren befinden:{" "}
          <a
            href="https://www.google.com/about/datacenters/inside/locations/?hl=de"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.google.com/about/datacenters/inside/locations/?hl=de
          </a>
        </p>
        <p>
          Ihre Daten werden auf verschiedenen physischen Datenträgern verteilt.
          Das hat den Vorteil, dass die Daten schneller abrufbar sind und vor
          Manipulation besser geschützt sind. In jedem Google-Rechenzentrum gibt
          es entsprechende Notfallprogramme für Ihre Daten. Wenn beispielsweise
          die Hardware bei Google ausfällt oder Naturkatastrophen Server
          lahmlegen, bleibt das Risiko einer Dienstunterbrechung bei Google
          dennoch gering.
        </p>
        <p>
          Standardisiert ist bei Google Analytics eine Aufbewahrungsdauer Ihrer
          Userdaten von 26 Monaten eingestellt. Dann werden Ihre Userdaten
          gelöscht. Allerdings haben wir die Möglichkeit, die Aufbewahrungsdauer
          von Nutzdaten selbst zu wählen. Dafür stehen uns fünf Varianten zur
          Verfügung:
        </p>
        <ul>
          <li>Löschung nach 14 Monaten</li>
          <li>Löschung nach 26 Monaten</li>
          <li>Löschung nach 38 Monaten</li>
          <li>Löschung nach 50 Monaten</li>
          <li>Keine automatische Löschung</li>
        </ul>
        <p>
          Wenn der festgelegte Zeitraum abgelaufen ist, werden einmal im Monat
          die Daten gelöscht. Diese Aufbewahrungsdauer gilt für Ihre Daten, die
          mit Cookies, Usererkennung und Werbe-IDs (z.B. Cookies der
          DoubleClick-Domain) verknüpft sind. Berichtergebnisse basieren auf
          aggregierten Daten und werden unabhängig von Nutzerdaten gespeichert.
          Aggregierte Daten sind eine Zusammenschmelzung von Einzeldaten zu
          einer größeren Einheit.
        </p>
        <h3>
          Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?
        </h3>
        <p>
          Nach dem Datenschutzrecht der Europäischen Union haben Sie das Recht,
          Auskunft über Ihre Daten zu erhalten, sie zu aktualisieren, zu löschen
          oder einzuschränken. Mithilfe des Browser-Add-ons zur Deaktivierung
          von Google Analytics-JavaScript (ga.js, analytics.js, dc.js)
          verhindern Sie, dass Google Analytics Ihre Daten verwendet. Das
          Browser-Add-on können Sie unter{" "}
          <a
            href="https://tools.google.com/dlpage/gaoptout?hl=de"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://tools.google.com/dlpage/gaoptout?hl=de
          </a>{" "}
          runterladen und installieren. Beachten Sie bitte, dass durch dieses
          Add-on nur die Datenerhebung durch Google Analytics deaktiviert wird.
        </p>
        <p>
          Falls Sie grundsätzlich Cookies (unabhängig von Google Analytics)
          deaktivieren, löschen oder verwalten wollen, gibt es für jeden Browser
          eine eigene Anleitung:
        </p>
        <p>
          <a
            href="https://support.google.com/chrome/answer/95647?tid=321234633"
            target="_blank"
            rel="noopener noreferrer"
          >
            Chrome: Cookies in Chrome löschen, aktivieren und verwalten
          </a>
        </p>
        <p>
          <a
            href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=321234633"
            target="_blank"
            rel="noopener noreferrer"
          >
            Safari: Verwalten von Cookies und Websitedaten mit Safari
          </a>
        </p>
        <p>
          <a
            href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=321234633"
            target="_blank"
            rel="noopener noreferrer"
          >
            Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf
            Ihrem Computer abgelegt haben
          </a>
        </p>
        <p>
          <a
            href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=321234633"
            target="_blank"
            rel="noopener noreferrer"
          >
            Internet Explorer: Löschen und Verwalten von Cookies
          </a>
        </p>
        <p>
          <a
            href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=321234633"
            target="_blank"
            rel="noopener noreferrer"
          >
            Microsoft Edge: Löschen und Verwalten von Cookies
          </a>
        </p>
        <p>
          Google Analytics ist aktiver Teilnehmer beim EU-U.S. Privacy Shield
          Framework, wodurch der korrekte und sichere Datentransfer persönlicher
          Daten geregelt wird. Mehr Informationen dazu finden Sie auf{" "}
          <a
            href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI"
            target="_blank"
            rel="follow noopener noreferrer"
          >
            https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;tid=321234633
          </a>
          . Wir hoffen wir konnten Ihnen die wichtigsten Informationen rund um
          die Datenverarbeitung von Google Analytics näherbringen. Wenn Sie mehr
          über den Tracking-Dienst erfahren wollen, empfehlen wir diese beiden
          Links:{" "}
          <a
            href="http://www.google.com/analytics/terms/de.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            http://www.google.com/analytics/terms/de.html
          </a>{" "}
          und{" "}
          <a
            href="https://support.google.com/analytics/answer/6004245?hl=de"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://support.google.com/analytics/answer/6004245?hl=de
          </a>
          .
        </p>
        <h2>Google Analytics IP-Anonymisierung</h2>
        <p>
          Wir haben auf dieser Webseite die IP-Adressen-Anonymisierung von
          Google Analytics implementiert. Diese Funktion wurde von Google
          entwickelt, damit diese Webseite die geltenden Datenschutzbestimmungen
          und Empfehlungen der lokalen Datenschutzbehörden einhalten kann, wenn
          diese eine Speicherung der vollständigen IP-Adresse untersagen. Die
          Anonymisierung bzw. Maskierung der IP findet statt, sobald die
          IP-Adressen im Google Analytics-Datenerfassungsnetzwerk eintreffen und
          bevor eine Speicherung oder Verarbeitung der Daten stattfindet.
        </p>
        <p>
          Mehr Informationen zur IP-Anonymisierung finden Sie auf 
          <a
            href="https://support.google.com/analytics/answer/2763052?hl=de"
            target="_blank"
            rel="noopener"
          >
            https://support.google.com/analytics/answer/2763052?hl=de
          </a>
          .
        </p>
        <h2>
          Google Analytics Berichte zu demografischen Merkmalen und Interessen
        </h2>
        <p>
          Wir haben in Google Analytics die Funktionen für Werbeberichte
          eingeschaltet. Die Berichte zu demografischen Merkmalen und Interessen
          enthalten Angaben zu Alter, Geschlecht und Interessen. Damit können
          wir uns &#8211; ohne diese Daten einzelnen Personen zuordnen zu können
          &#8211; ein besseres Bild von unseren Nutzern machen. Mehr über die
          Werbefunktionen erfahren Sie{" "}
          <a
            href="https://support.google.com/analytics/answer/3450482?hl=de_AT&amp;utm_id=ad"
            target="_blank"
            rel="noopener"
          >
            auf https://support.google.com/analytics/answer/3450482?hl=de_AT&amp;utm_id=ad
          </a>
          .
        </p>
        <p>
          Sie können die Nutzung der Aktivitäten und Informationen Ihres Google
          Kontos unter “Einstellungen für Werbung” auf{" "}
          <a href="https://adssettings.google.com/authenticated">
            https://adssettings.google.com/authenticated
          </a>{" "}
          per Checkbox beenden.
        </p>
        <h2>Google Analytics Deaktivierungslink</h2>
        <p>
          Wenn Sie auf folgenden <strong>Deaktivierungslink</strong> klicken,
          können Sie verhindern, dass Google weitere Besuche auf dieser Webseite
          erfasst. Achtung: Das Löschen von Cookies, die Nutzung des
          Inkognito/Privatmodus Ihres Browsers, oder die Nutzung eines anderen
          Browsers führt dazu, dass wieder Daten erhoben werden.
        </p>
        <p>
          <a
            onClick={() => alert("Google Analytics wurde deaktiviert")}
            href="javascript:gaOptout()"
          >
            Google Analytics deaktivieren
          </a>
        </p>
        <h2>Google Analytics Zusatz zur Datenverarbeitung</h2>
        <p>
          Wir haben mit Google einen Direktkundenvertrag zur Verwendung von
          Google Analytics abgeschlossen, indem wir den “Zusatz zur
          Datenverarbeitung” in Google Analytics akzeptiert haben.
        </p>
        <p>
          Mehr über den Zusatz zur Datenverarbeitung für Google Analytics finden
          Sie hier:{" "}
          <a
            href="https://support.google.com/analytics/answer/3379636?hl=de&amp;utm_id=ad"
            target="_blank"
            rel="noopener"
          >
            https://support.google.com/analytics/answer/3379636?hl=de&amp;utm_id=ad
          </a>
        </p>
        <h2>Google Analytics Google-Signale Datenschutzerklärung</h2>
        <p>
          Wir haben in Google Analytics die Google-Signale aktiviert. So werden
          die bestehenden Google-Analytics-Funktionen (Werbeberichte,
          Remarketing, gerätübergreifende Berichte und Berichte zu Interessen
          und demografische Merkmale) aktualisiert, um zusammengefasste und
          anonymisierte Daten von Ihnen zu erhalten, sofern Sie personalisierte
          Anzeigen in Ihrem Google-Konto erlaubt haben.
        </p>
        <p>
          Das besondere daran ist, dass es sich dabei um ein
          Cross-Device-Tracking handelt. Das heißt Ihre Daten können
          geräteübergreifend analysiert werden. Durch die Aktivierung von
          Google-Signale werden Daten erfasst und mit dem Google-Konto
          verknüpft. Google kann dadurch zum Beispiel erkennen, wenn Sie auf
          unsere Webseite über ein Smartphone ein Produkt ansehen und erst
          später über einen Laptop das Produkt kaufen. Dank der Aktivierung von
          Google-Signale können wir gerätübergreifende Remarketing-Kampagnen
          starten, die sonst in dieser Form nicht möglich wären. Remarketing
          bedeutet, dass wir Ihnen auch auf anderen Webseiten unser Angebot
          zeigen können.
        </p>
        <p>
          In Google Analytics werden zudem durch die Google-Signale weitere
          Besucherdaten wie Standort, Suchverlauf, YouTube-Verlauf und Daten
          über Ihre Handlungen auf unserer Webseite, erfasst. Wir erhalten
          dadurch von Google bessere Werbeberichte und nützlichere Angaben zu
          Ihren Interessen und demografischen Merkmalen. Dazu gehören Ihr Alter,
          welche Sprache sie sprechen, wo Sie wohnen oder welchem Geschlecht Sie
          angehören. Weiters kommen auch noch soziale Kriterien wie Ihr Beruf,
          Ihr Familienstand oder Ihr Einkommen hinzu. All diese Merkmal helfen
          Google Analytics Personengruppen bzw. Zielgruppen zu definieren.
        </p>
        <p>
          Die Berichte helfen uns auch Ihr Verhalten, Ihre Wünsche und
          Interessen besser einschätzen zu können. Dadurch können wir unsere
          Dienstleistungen und Produkte für Sie optimieren und anpassen. Diese
          Daten laufen standardmäßig nach 26 Monaten ab. Bitte beachten Sie,
          dass diese Datenerfassung nur erfolgt, wenn Sie personalisierte
          Werbung in Ihrem Google-Konto zugelassen haben. Es handelt sich dabei
          immer um zusammengefasste und anonyme Daten und nie um Daten einzelner
          Personen. In Ihrem Google-Konto können Sie diese Daten verwalten bzw.
          auch löschen.
        </p>
        <h2>Facebook-Pixel Datenschutzerklärung</h2>
        <p>
          Wir verwenden auf unserer Webseite das Facebook-Pixel von Facebook.
          Dafür haben wir einen Code auf unserer Webseite implementiert. Der
          Facebook-Pixel ist ein Ausschnitt aus JavaScript-Code, der eine
          Ansammlung von Funktionen lädt, mit denen Facebook Ihre Userhandlungen
          verfolgen kann, sofern Sie über Facebook-Ads auf unsere Webseite
          gekommen sind. Wenn Sie beispielsweise ein Produkt auf unserer
          Webseite erwerben, wird das Facebook-Pixel ausgelöst und speichert
          Ihre Handlungen auf unserer Webseite in einem oder mehreren Cookies.
          Diese Cookies ermöglichen es Facebook Ihre Userdaten (Kundendaten wie
          IP-Adresse, User-ID) mit den Daten Ihres Facebook-Kontos abzugleichen.
          Dann löscht Facebook diese Daten wieder. Die erhobenen Daten sind für
          uns anonym und nicht einsehbar und werden nur im Rahmen von
          Werbeanzeigenschaltungen nutzbar. Wenn Sie selbst Facebook-User sind
          und angemeldet sind, wird der Besuch unserer Webseite automatisch
          Ihrem Facebook-Benutzerkonto zugeordnet.
        </p>
        <p>
          Wir wollen unsere Dienstleistungen bzw. Produkte nur jenen Menschen
          zeigen, die sich auch wirklich dafür interessieren. Mithilfe von
          Facebook-Pixel können unsere Werbemaßnahmen besser auf Ihre Wünsche
          und Interessen abgestimmt werden. So bekommen Facebook-User (sofern
          sie personalisierte Werbung erlaubt haben) passende Werbung zu sehen.
          Weiters verwendet Facebook die erhobenen Daten zu Analysezwecken und
          eigenen Werbeanzeigen.
        </p>
        <p>
          Im Folgenden zeigen wir Ihnen jene Cookies, die durch das Einbinden
          von Facebook-Pixel auf einer Testseite gesetzt wurden. Bitte beachten
          Sie, dass dies nur Beispiel-Cookies sind. Je nach Interaktion auf
          unserer Webseite werden unterschiedliche Cookies gesetzt.
        </p>
        <p>
          <strong>Name:</strong> _fbp
          <br />
          <strong>Wert:</strong> fb.1.1568287647279.257405483-6321234633-7
          <br />
          <strong>Verwendungszweck:</strong> Dieses Cookie verwendet Facebook,
          um Werbeprodukte anzuzeigen.
          <br />
          <strong>Ablaufdatum:</strong> nach 3 Monaten
        </p>
        <p>
          <strong>Name:</strong> fr
          <br />
          <strong>Wert:</strong> 0aPf312HOS5Pboo2r..Bdeiuf&#8230;1.0.Bdeiuf.
          <br />
          <strong>Verwendungszweck:</strong> Dieses Cookie wird verwendet, damit
          Facebook-Pixel auch ordentlich funktioniert.
          <br />
          <strong>Ablaufdatum:</strong> nach 3 Monaten
        </p>
        <p>
          <strong>Name:</strong>{" "}
          comment_author_50ae8267e2bdf1253ec1a5769f48e062321234633-3
          <br />
          <strong>Wert:</strong> Name des Autors
          <br />
          <strong>Verwendungszweck:</strong> Dieses Cookie speichert den Text
          und den Namen eines Users, der beispielsweise einen Kommentar
          hinterlässt.
          <br />
          <strong>Ablaufdatum:</strong> nach 12 Monaten
        </p>
        <p>
          <strong>Name:</strong>{" "}
          comment_author_url_50ae8267e2bdf1253ec1a5769f48e062
          <br />
          <strong>Wert:</strong> https%3A%2F%2Fwww.testseite…%2F (URL des
          Autors)
          <br />
          <strong>Verwendungszweck:</strong> Dieses Cookie speichert die URL der
          Website, die der User in einem Textfeld auf unserer Webseite eingibt.
          <br />
          <strong>Ablaufdatum:</strong> nach 12 Monaten
        </p>
        <p>
          <strong>Name:</strong>{" "}
          comment_author_email_50ae8267e2bdf1253ec1a5769f48e062
          <br />
          <strong>Wert:</strong> E-Mail-Adresse des Autors
          <br />
          <strong>Verwendungszweck:</strong> Dieses Cookie speichert die
          E-Mail-Adresse des Users, sofern er sie auf der Website bekannt
          gegeben hat.
          <br />
          <strong>Ablaufdatum:</strong> nach 12 Monaten
        </p>
        <p>
          <strong>Anmerkung: </strong>Die oben genannten Cookies beziehen sich
          auf ein individuelles Userverhalten. Speziell bei der Verwendung von
          Cookies sind Veränderungen bei Facebook nie auszuschließen.
        </p>
        <p>
          Sofern Sie bei Facebook angemeldet sind, können Sie Ihre Einstellungen
          für Werbeanzeigen unter{" "}
          <a
            href="https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen"
            target="_blank"
            rel="noopener"
          >
            https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen
          </a>
           selbst verändern. Falls Sie kein Facebook-User sind, können Sie auf{" "}
          <a
            href="http://www.youronlinechoices.com/de/praferenzmanagement/?tid=321234633"
            target="_blank"
            rel="noopener"
          >
            http://www.youronlinechoices.com/de/praferenzmanagement/
          </a>{" "}
          grundsätzlich Ihre nutzungsbasierte Online-Werbung verwalten. Dort
          haben Sie die Möglichkeit, Anbieter zu deaktivieren bzw. zu
          aktivieren.
        </p>
        <p>
          Wenn Sie mehr über den Datenschutz von Facebook erfahren wollen,
          empfehlen wir Ihnen die eigenen Datenrichtlinien des Unternehmens auf{" "}
          <a
            href="https://www.facebook.com/policy.php"
            target="_blank"
            rel="noopener"
          >
            https://www.facebook.com/policy.php
          </a>
          .
        </p>
        <h2>
          Facebook Automatischer erweiterter Abgleich Datenschutzerklärung
        </h2>
        <p>
          Wir haben im Rahmen der Facebook-Pixel-Funktion auch den automatischen
          erweiterten Abgleich (engl. Automatic Advanced Matching) aktiviert.
          Diese Funktion des Pixels ermöglicht uns, gehashte E-Mails, Namen,
          Geschlecht, Stadt, Bundesland, Postleitzahl und Geburtsdatum oder
          Telefonnummer als zusätzliche Informationen an Facebook zu senden,
          sofern Sie uns diese Daten zur Verfügung gestellt haben. Diese
          Aktivierung ermöglicht uns Werbekampagnen auf Facebook noch genauer
          auf Menschen, die sich für unsere Dienstleistungen oder Produkte
          interessieren, anzupassen.
        </p>
        <h2>Eingebettete Social Media Elemente Datenschutzerklärung</h2>
        <p>
          Wir binden auf unserer Webseite Elemente von Social Media Diensten ein
          um Bilder, Videos und Texte anzuzeigen.
          <br />
          Durch den Besuch von Seiten die diese Elemente darstellen, werden
          Daten von Ihrem Browser zum jeweiligen Social Media Dienst übertragen
          und dort gespeichert. Wir haben keinen Zugriff auf diese Daten.
          <br />
          Die folgenden Links führen Sie zu den Seiten der jeweiligen Social
          Media Dienste wo erklärt wird, wie diese mit Ihren Daten umgehen:
        </p>
        <ul>
          <li>
            Instagram-Datenschutzrichtlinie:{" "}
            <a
              href="https://help.instagram.com/519522125107875?tid=321234633"
              target="_blank"
              rel="noopener"
            >
              https://help.instagram.com/519522125107875
            </a>
          </li>
          <li>
            Für YouTube gilt die Google Datenschutzerklärung:{" "}
            <a
              href="https://policies.google.com/privacy?hl=de&amp;tid=321234633"
              target="_blank"
              rel="noopener"
            >
              https://policies.google.com/privacy?hl=de
            </a>
          </li>
          <li>
            Facebook-Datenrichtline: 
            <a
              href="https://www.facebook.com/about/privacy?tid=321234633"
              target="_blank"
              rel="noopener"
            >
              https://www.facebook.com/about/privacy
            </a>
          </li>
          <li>
            Twitter Datenschutzrichtlinie: 
            <a
              href="https://twitter.com/de/privacy?tid=321234633"
              target="_blank"
              rel="noopener"
            >
              https://twitter.com/de/privacy
            </a>
          </li>
        </ul>
        <h2>Facebook Datenschutzerklärung</h2>
        <p>
          Wir verwenden auf unserer Webseite ausgewählte Tools von Facebook.
          Facebook ist ein Social Media Network des Unternehmens Facebook
          Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2
          Ireland. Mithilfe dieser Tools können wir Ihnen und Menschen, die sich
          für unsere Produkte und Dienstleistungen interessieren, das
          bestmögliche Angebot bieten. Im Folgenden geben wir einen Überblick
          über die verschiedenen Facebook Tools, welche Daten an Facebook
          gesendet werden und wie Sie diese Daten löschen können.
        </p>
        <h3>Was sind Facebook-Tools?</h3>
        <p>
          Neben vielen anderen Produkten bietet Facebook auch die sogenannten
          &#8220;Facebook Business Tools&#8221; an. Das ist die offizielle
          Bezeichnung von Facebook. Da der Begriff aber kaum bekannt ist, haben
          wir uns dafür entschieden, sie lediglich Facebook-Tools zu nennen.
          Darunter finden sich unter anderem:
        </p>
        <ul>
          <li>Facebook-Pixel</li>
          <li>
            soziale Plug-ins (wie z.B der „Gefällt mir“- oder „Teilen“-Button)
          </li>
          <li>Facebook Login</li>
          <li>Account Kit</li>
          <li>APIs (Programmierschnittstelle)</li>
          <li>SDKs (Sammlung von Programmierwerkzeugen)</li>
          <li>Plattform-Integrationen</li>
          <li>Plugins</li>
          <li>Codes</li>
          <li>Spezifikationen</li>
          <li>Dokumentationen</li>
          <li>Technologien und Dienstleistungen</li>
        </ul>
        <p>
          Durch diese Tools erweitert Facebook Dienstleistungen und hat die
          Möglichkeit, Informationen über User-Aktivitäten außerhalb von
          Facebook zu erhalten.
        </p>
        <h3>Warum verwenden wir Facebook-Tools auf unserer Webseite?</h3>
        <p>
          Wir wollen unsere Dienstleistungen und Produkte nur Menschen zeigen,
          die sich auch wirklich dafür interessieren. Mithilfe von Werbeanzeigen
          (Facebook-Ads) können wir genau diese Menschen erreichen. Damit den
          Usern passende Werbung gezeigt werden kann, benötigt Facebook
          allerdings Informationen über die Wünsche und Bedürfnisse der
          Menschen. So werden dem Unternehmen Informationen über das
          Userverhalten (und Kontaktdaten) auf unserer Webseite zur Verfügung
          gestellt. Dadurch sammelt Facebook bessere User-Daten und kann
          interessierten Menschen die passende Werbung über unsere Produkte bzw.
          Dienstleistungen anzeigen. Die Tools ermöglichen somit
          maßgeschneiderte Werbekampagnen auf Facebook.
        </p>
        <p>
          Daten über Ihr Verhalten auf unserer Webseite nennt Facebook
          „Event-Daten“. Diese werden auch für Messungs- und Analysedienste
          verwendet. Facebook kann so in unserem Auftrag „Kampagnenberichte“
          über die Wirkung unserer Werbekampagnen erstellen. Weiters bekommen
          wir durch Analysen einen besseren Einblick, wie Sie unsere
          Dienstleistungen, Webseite oder Produkte verwenden. Dadurch optimieren
          wir mit einigen dieser Tools Ihre Nutzererfahrung auf unserer
          Webseite. Beispielsweise können Sie mit den sozialen Plug-ins Inhalte
          auf unserer Seite direkt auf Facebook teilen.
        </p>
        <h3>Welche Daten werden von Facebook-Tools gespeichert?</h3>
        <p>
          Durch die Nutzung einzelner Facebook-Tools können personenbezogene
          Daten (Kundendaten) an Facebook gesendet werden. Abhängig von den
          benutzten Tools können Kundendaten wie Name, Adresse, Telefonnummer
          und IP-Adresse versandt werden.
        </p>
        <p>
          Facebook verwendet diese Informationen, um die Daten mit den Daten,
          die es selbst von Ihnen hat (sofern Sie Facebook-Mitglied sind)
          abzugleichen. Bevor Kundendaten an Facebook übermittelt werden,
          erfolgt ein sogenanntes „Hashing“. Das bedeutet, dass ein beliebig
          großer Datensatz in eine Zeichenkette transformiert wird. Dies dient
          auch der Verschlüsselung von Daten.
        </p>
        <p>
          Neben den Kontaktdaten werden auch „Event-Daten“ übermittelt. Unter
          „Event-Daten“ sind jene Informationen gemeint, die wir über Sie auf
          unserer Webseite erhalten. Zum Beispiel, welche Unterseiten Sie
          besuchen oder welche Produkte Sie bei uns kaufen. Facebook teilt die
          erhaltenen Informationen nicht mit Drittanbietern (wie beispielsweise
          Werbetreibende), außer das Unternehmen hat eine explizite Genehmigung
          oder ist rechtlich dazu verpflichtet. „Event-Daten“ können auch mit
          Kontaktdaten verbunden werden. Dadurch kann Facebook bessere
          personalisierte Werbung anbieten. Nach dem bereits erwähnten
          Abgleichungsprozess löscht Facebook die Kontaktdaten wieder.
        </p>
        <p>
          Um Werbeanzeigen optimiert ausliefern zu können, verwendet Facebook
          die Event-Daten nur, wenn diese mit anderen Daten (die auf andere
          Weise von Facebook erfasst wurden) zusammengefasst wurden. Diese
          Event-Daten nützt Facebook auch für Sicherheits-, Schutz-,
          Entwicklungs- und Forschungszwecken. Viele dieser Daten werden über
          Cookies zu Facebook übertragen. Cookies sind kleine Text-Dateien, die
          zum Speichern von Daten bzw. Informationen in Browsern verwendet
          werden. Je nach verwendeten Tools und abhängig, ob Sie
          Facebook-Mitglied sind, werden unterschiedlich viele Cookies in Ihrem
          Browser angelegt. In den Beschreibungen der einzelnen Facebook Tools
          gehen wir näher auf einzelne Facebook-Cookies ein. Allgemeine
          Informationen über die Verwendung von Facebook-Cookies erfahren Sie
          auch auf{" "}
          <a
            href="https://www.facebook.com/policies/cookies?tid=321234633"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.facebook.com/policies/cookies
          </a>
          .
        </p>
        <h3>Wie lange und wo werden die Daten gespeichert?</h3>
        <p>
          Grundsätzlich speichert Facebook Daten bis sie nicht mehr für die
          eigenen Dienste und Facebook-Produkte benötigt werden. Facebook hat
          auf der ganzen Welt Server verteilt, wo Ihre Daten gespeichert werden.
          Kundendaten werden allerdings, nachdem sie mit den eigenen Userdaten
          abgeglichen wurden, innerhalb von 48 Stunden gelöscht.
        </p>
        <h3>
          Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?
        </h3>
        <p>
          Entsprechend der Datenschutz Grundverordnung haben Sie das Recht auf
          Auskunft, Berichtigung, Übertragbarkeit und Löschung Ihrer Daten.
        </p>
        <p>
          Eine komplette Löschung der Daten erfolgt nur, wenn Sie Ihr
          Facebook-Konto vollständig löschen. Und so funktioniert das Löschen
          Ihres Facebook-Kontos:
        </p>
        <p>1) Klicken Sie rechts bei Facebook auf Einstellungen.</p>
        <p>
          2) Anschließend klicken Sie in der linken Spalte auf „Deine
          Facebook-Informationen“.
        </p>
        <p>3) Nun klicken Sie &#8220;Deaktivierung und Löschung&#8221;.</p>
        <p>
          4) Wählen Sie jetzt „Konto löschen“ und klicken Sie dann auf „Weiter
          und Konto löschen“
        </p>
        <p>
          5) Geben Sie nun Ihr Passwort ein, klicken Sie auf „Weiter“ und dann
          auf „Konto löschen“
        </p>
        <p>
          Die Speicherung der Daten, die Facebook über unsere Seite erhält,
          erfolgt unter anderem über Cookies (z.B. bei sozialen Plugins). In
          Ihrem Browser können Sie einzelne oder alle Cookies deaktivieren,
          löschen oder verwalten. Je nach dem welchen Browser Sie verwenden,
          funktioniert dies auf unterschiedliche Art und Weise. Die folgenden
          Anleitungen zeigen, wie Sie Cookies in Ihrem Browser verwalten:
        </p>
        <p>
          <a
            href="https://support.google.com/chrome/answer/95647?tid=321234633"
            target="_blank"
            rel="noopener noreferrer"
          >
            Chrome: Cookies in Chrome löschen, aktivieren und verwalten
          </a>
        </p>
        <p>
          <a
            href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=321234633"
            target="_blank"
            rel="noopener noreferrer"
          >
            Safari: Verwalten von Cookies und Websitedaten mit Safari
          </a>
        </p>
        <p>
          <a
            href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=321234633"
            target="_blank"
            rel="noopener noreferrer"
          >
            Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf
            Ihrem Computer abgelegt haben
          </a>
        </p>
        <p>
          <a
            href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=321234633"
            target="_blank"
            rel="noopener noreferrer"
          >
            Internet Explorer: Löschen und Verwalten von Cookies
          </a>
        </p>
        <p>
          <a
            href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=321234633"
            target="_blank"
            rel="noopener noreferrer"
          >
            Microsoft Edge: Löschen und Verwalten von Cookies
          </a>
        </p>
        <p>
          Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren
          Browser so einrichten, dass er Sie immer informiert, wenn ein Cookie
          gesetzt werden soll. So können Sie bei jedem einzelnen Cookie
          entscheiden, ob Sie es erlauben oder nicht.
        </p>
        <p>
          Facebook ist aktiver Teilnehmer beim EU-U.S. Privacy Shield Framework,
          wodurch der korrekte und sichere Datentransfer persönlicher Daten
          geregelt wird. Mehr Informationen dazu finden Sie auf{" "}
          <a
            href="https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC"
            target="_blank"
            rel="follow noopener noreferrer"
          >
            https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC
          </a>
          . Wir hoffen wir haben Ihnen die wichtigsten Informationen über die
          Nutzung und Datenverarbeitung durch die Facebook-Tools nähergebracht.
          Wenn Sie mehr darüber erfahren wollen, wie Facebook Ihre Daten
          verwendet, empfehlen wir Ihnen die Datenrichtlinien auf{" "}
          <a
            href="https://www.facebook.com/about/privacy/update"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.facebook.com/about/privacy/update
          </a>
          .
        </p>
        <h2>Facebook Soziale Plug-ins Datenschutzerklärung</h2>
        <p>
          Auf unserer Webseite sind sogenannte soziale Plug-ins des Unternehmens
          Facebook Inc. eingebaut. Sie erkennen diese Buttons am klassischen
          Facebook-Logo, wie dem „Gefällt mir“-Button (die Hand mit erhobenem
          Daumen) oder an einer eindeutigen „Facebook Plug-in“-Kennzeichnung.
          Ein soziales Plug-in ist ein kleiner Teil von Facebook, der in unsere
          Seite integriert ist. Jedes Plug-in hat eine eigene Funktion. Die am
          meisten verwendeten Funktionen sind die bekannten &#8220;Gefällt
          mir&#8221;- und &#8220;Teilen&#8221;-Buttons.
        </p>
        <p>Folgende soziale Plug-ins werden von Facebook angeboten:</p>
        <ul>
          <li>&#8220;Speichern&#8221;-Button</li>
          <li>&#8220;Gefällt mir&#8221;-Button, Teilen, Senden und Zitat</li>
          <li>Seiten-Plug-in</li>
          <li>Kommentare</li>
          <li>Messenger-Plug-in</li>
          <li>Eingebettete Beiträge und Videoplayer</li>
          <li>Gruppen-Plug-in</li>
        </ul>
        <p>
          Auf{" "}
          <a
            href="https://developers.facebook.com/docs/plugins"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://developers.facebook.com/docs/plugins
          </a>{" "}
          erhalten Sie nähere Informationen, wie die einzelnen Plug-ins
          verwendet werden. Wir nützen die sozialen Plug-ins einerseits, um
          Ihnen ein besseres Usererlebnis auf unserer Seite zu bieten,
          andererseits weil Facebook dadurch unsere Werbeanzeigen optimieren
          kann.
        </p>
        <p>
          Sofern Sie ein Facebook-Konto haben oder{" "}
          <a
            href="https://www.facebook.com/"
            target="_blank"
            rel="follow noopener noreferrer"
          >
            facebook.com
          </a>{" "}
          schon mal besucht haben, hat Facebook bereits mindestens ein Cookie in
          Ihrem Browser gesetzt. In diesem Fall sendet Ihr Browser über dieses
          Cookie Informationen an Facebook, sobald Sie unsere Seite besuchen
          bzw. mit sozialen Plug-ins (z.B. dem „Gefällt mir“-Button)
          interagieren.
        </p>
        <p>
          Die erhaltenen Informationen werden innerhalb von 90 Tagen wieder
          gelöscht bzw. anonymisiert. Laut Facebook gehören zu diesen Daten Ihre
          IP-Adresse, welche Webseite Sie besucht haben, das Datum, die Uhrzeit
          und weitere Informationen, die Ihren Browser betreffen.
        </p>
        <p>
          Um zu verhindern, dass Facebook während Ihres Besuches auf unserer
          Webseite viele Daten sammelt und mit den Facebook-Daten verbindet,
          müssen Sie sich während des Webseitenbesuchs von Facebook abmelden
          (ausloggen).
        </p>
        <p>
          Falls Sie bei Facebook nicht angemeldet sind oder kein Facebook-Konto
          besitzen, sendet Ihr Browser weniger Informationen an Facebook, weil
          Sie weniger Facebook-Cookies haben. Dennoch können Daten wie
          beispielsweise Ihre IP-Adresse oder welche Webseite Sie besuchen an
          Facebook übertragen werden. Wir möchten noch ausdrücklich darauf
          hinweisen, dass wir über die genauen Inhalte der Daten nicht exakt
          Bescheid wissen. Wir versuchen aber Sie nach unserem aktuellen
          Kenntnisstand so gut als möglich über die Datenverarbeitung
          aufzuklären. Wie Facebook die Daten nutzt, können Sie auch in den
          Datenrichtline des Unternehmens unter{" "}
          <a
            href="https://www.facebook.com/about/privacy/update"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.facebook.com/about/privacy/update
          </a>{" "}
          nachlesen.
        </p>
        <p>
          Folgende Cookies werden in Ihrem Browser mindestens gesetzt, wenn Sie
          eine Webseite mit sozialen Plug-ins von Facebook besuchen:
        </p>
        <p>
          <strong>Name:</strong> dpr
          <br />
          <strong>Wert:</strong> keine Angabe
          <br />
          <strong>Verwendungszweck:</strong> Dieses Cookie wird verwendet, damit
          die sozialen Plug-ins auf unserer Webseite funktionieren.
          <br />
          <strong>Ablaufdatum:</strong> nach Sitzungsende
        </p>
        <p>
          <strong>Name:</strong> fr
          <br />
          <strong>Wert:</strong>{" "}
          0jieyh4321234633c2GnlufEJ9..Bde09j&#8230;1.0.Bde09j
          <br />
          <strong>Verwendungszweck:</strong> Auch das Cookie ist nötig, dass die
          Plug-ins einwandfrei funktionieren.
          <br />
          <strong>Ablaufdatum::</strong> nach 3 Monaten
        </p>
        <p>
          <strong>Anmerkung:</strong> Diese Cookies wurden nach einem Test
          gesetzt, auch wenn Sie nicht Facebook-Mitglied sind.
        </p>
        <p>
          Sofern Sie bei Facebook angemeldet sind, können Sie Ihre Einstellungen
          für Werbeanzeigen unter{" "}
          <a
            href="https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen
          </a>
           selbst verändern. Falls Sie kein Facebook-User sind, können Sie auf{" "}
          <a
            href="http://www.youronlinechoices.com/de/praferenzmanagement/?tid=321234633"
            target="_blank"
            rel="noopener noreferrer"
          >
            http://www.youronlinechoices.com/de/praferenzmanagement/
          </a>
          grundsätzlich Ihre nutzungsbasierte Online-Werbung verwalten. Dort
          haben Sie die Möglichkeit, Anbieter zu deaktivieren bzw. zu
          aktivieren.
        </p>
        <p>
          Wenn Sie mehr über den Datenschutz von Facebook erfahren wollen,
          empfehlen wir Ihnen die eigenen Datenrichtlinien des Unternehmens auf{" "}
          <a
            href="https://www.facebook.com/policy.php?tip=321234633"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.facebook.com/policy.php
          </a>
          .
        </p>
        <h2>Facebook Login Datenschutzerklärung</h2>
        <p>
          Wir haben auf unserer Seite das praktische Facebook Login integriert.
          So können Sie sich bei uns ganz einfach mit Ihrem Facebook-Konto
          einloggen, ohne ein weiteres Benutzerkonto anlegen zu müssen. Wenn Sie
          sich entscheiden, Ihre Registrierung über das Facebook Login zu
          machen, werden Sie auf das Social Media Network Facebook
          weitergeleitet. Dort erfolgt die Anmeldung über Ihre Facebook
          Nutzerdaten. Durch dieses Login-Verfahren werden Daten über Sie bzw.
          Ihr Userverhalten gespeichert und an Facebook übermittelt.
        </p>
        <p>
          Um die Daten zu speichern, benutzt Facebook verschiedene Cookies. Im
          Folgenden zeigen wir Ihnen die wichtigsten Cookies, die in Ihrem
          Browser gesetzt werden bzw. schon bestehen, wenn Sie sich über das
          Facebook Login auf unserer Seite anmelden:
        </p>
        <p>
          <strong>Name:</strong> fr
          <br />
          <strong>Wert:</strong> 0jieyh4c2GnlufEJ9..Bde09j&#8230;1.0.Bde09j
          <br />
          <strong>Verwendungszweck:</strong> Dieses Cookie wird verwendet, damit
          das soziale Plugin auf unserer Webseite bestmöglich funktioniert.
          <br />
          <strong>Ablaufdatum:</strong> nach 3 Monaten
        </p>
        <p>
          <strong>Name:</strong> datr
          <br />
          <strong>Wert:</strong> 4Jh7XUA2321234633SEmPsSfzCOO4JFFl
          <br />
          <strong>Verwendungszweck:</strong> Facebook setzt das
          &#8220;datr&#8221;-Cookie, wenn ein Webbrowser auf facebook.com
          zugreift, und das Cookie hilft, Anmeldeaktivitäten zu identifizieren
          und die Benutzer zu schützen.
          <br />
          <strong>Ablaufdatum:</strong> nach 2 Jahren
        </p>
        <p>
          <strong>Name:</strong> _js_datr
          <br />
          <strong>Wert:</strong> deleted
          <br />
          <strong>Verwendungszweck:</strong> Dieses Session-Cookie setzt
          Facebook zu Trackingzwecken, auch wenn Sie kein Facebook-Konto haben
          oder ausgeloggt sind.
          <br />
          <strong>Ablaufdatum:</strong> nach Sitzungsende
        </p>
        <p>
          <strong>Anmerkung:</strong> Die angeführten Cookies sind nur eine
          kleine Auswahl der Cookies, die Facebook zur Verfügung stehen. Weitere
          Cookies sind beispielsweise _ fbp, sb oder wd. Eine vollständige
          Aufzählung ist nicht möglich, da Facebook über eine Vielzahl an
          Cookies verfügt und diese variabel einsetzt.
        </p>
        <p>
          Der Facebook Login bietet Ihnen einerseits einen schnellen und
          einfachen Registrierungsprozess, andererseits haben wir so die
          Möglichkeit Daten mit Facebook zu teilen. Dadurch können wir unser
          Angebot und unsere Werbeaktionen besser an Ihre Interessen und
          Bedürfnisse anpassen. Daten, die wir auf diese Weise von Facebook
          erhalten, sind öffentliche Daten wie
        </p>
        <ul>
          <li>Ihr Facebook-Name</li>
          <li>Ihr Profilbild</li>
          <li>eine hinterlegte E-Mail-Adresse</li>
          <li>Freundeslisten</li>
          <li>Buttons-Angaben (z.B. „Gefällt mir“-Button)</li>
          <li>Geburtstagsdatum</li>
          <li>Sprache</li>
          <li>Wohnort</li>
        </ul>
        <p>
          Im Gegenzug stellen wir Facebook Informationen über Ihre Aktivitäten
          auf unserer Webseite bereit. Das sind unter anderem Informationen über
          Ihr verwendetes Endgerät, welche Unterseiten Sie bei uns besuchen oder
          welche Produkte Sie bei uns erworben haben.
        </p>
        <p>
          Durch die Verwendung von Facebook Login willigen Sie der
          Datenverarbeitung ein. Sie können diese Vereinbarung jederzeit
          widerrufen. Wenn Sie mehr Informationen über die Datenverarbeitung
          durch Facebook erfahren wollen, empfehlen wir Ihnen die
          Facebook-Datenschutzerklärung unter{" "}
          <a
            href="https://de-de.facebook.com/policy.php?tid=321234633"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://de-de.facebook.com/policy.php
          </a>
          .
        </p>
        <p>
          Sofern Sie bei Facebook angemeldet sind, können Sie Ihre Einstellungen
          für Werbeanzeigen unter{" "}
          <a
            href="https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen
          </a>
           selbst verändern.
        </p>
        <p style={{ marginTop: 15 }}>
          Quelle: Erstellt mit dem 
          <a
            title="Datenschutz Generator Deutschland"
            href="https://www.adsimple.de/datenschutz-generator/"
            target="_blank"
            rel="follow"
            style={{ textDecoration: "none" }}
          >
            Datenschutz Generator
          </a>{" "}
          von AdSimple in Kooperation mit{" "}
          <a
            href="https://www.slashtechnik.de"
            target="_blank"
            rel="follow"
            title=""
          >
            slashtechnik.de
          </a>
        </p>
      </div>
    </div>
  );
}

export default Imprint;
