import React from "react";
import { GlobalContext, IGlobalContext } from "../GlobalContextProvider";
import { useParams } from "react-router-dom";
import { q } from "../Tools";

const lfCfg = require("../lf-config-frontend.json");

const Activate: React.FC = () => {
  const globalContext: IGlobalContext = React.useContext(GlobalContext);
  const params = useParams();

  const activate = async () => {
    q(`activating user ...`);
    try {
      const result = await fetch(lfCfg.backend + "/_activate", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          code: params.code,
        }),
      });

      const data = await result.json();
      if (data.errorId === 0) {
        q(`logged in: ${JSON.stringify(data)}`);
        globalContext.dispatch({ type: "SET_USER", value: data.user });
        window.location.href = lfCfg.frontend + "/_setup";
      }
    } catch (e) {
      q(`error signing up: ${e}`);
    }
  };

  React.useEffect(() => {
    activate();
  }, []);

  return <div />;
};

export default Activate;
