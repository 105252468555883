import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TermsRaw from "./TermsRaw";

interface ITermsDialogProps {
  open: boolean;
  handleAccept: () => void;
  handleCancel: () => void;
}

export default function TermsDialog(props: ITermsDialogProps) {
  const { open, handleAccept, handleCancel } = props;

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Terms of Service</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <h2>Welcome to Linkbox.io!</h2>
          <p>
            Linkbox is a free, open link repository where users can post their
            links inside
            <ul>
              <li>public boxes and share them with their friends</li>
              <li>private boxes which cannot be shared</li>
            </ul>
          </p>
          <p>Please accept our terms of service before you proceed.</p>
          <div style={{ height: 500, overflow: "scroll" }}>
            <TermsRaw />
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Disagree</Button>
        <Button onClick={handleAccept} autoFocus>
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  );
}
