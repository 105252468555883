import Box from "@mui/material/Box";
import TermsRaw from "./TermsRaw";

function Terms() {
  return (
    <Box
      sx={{
        padding: 4,
      }}
    >
      <TermsRaw />
    </Box>
  );
}

export default Terms;
