import React from "react";
import AddLinkIcon from "@mui/icons-material/AddLink";
import AddFolderIcon from "@mui/icons-material/CreateNewFolder";
import Fab from "@mui/material/Fab";
import Box from "@mui/material/Box";

export interface IRepoFABProps {
  handleAddLinkClick: () => void;
  handleAddFolderClick: () => void;
}

const RepoFAB: React.FC<IRepoFABProps> = ({
  handleAddLinkClick,
  handleAddFolderClick,
}) => {
  return (
    <>
      <Box sx={{ position: "fixed", right: 16, bottom: 16 }}>
        <Fab
          color="primary"
          aria-label="add"
          style={{ marginRight: 16 }}
          onClick={handleAddLinkClick}
        >
          <AddLinkIcon />
        </Fab>
        <Fab color="secondary" aria-label="edit" onClick={handleAddFolderClick}>
          <AddFolderIcon />
        </Fab>
      </Box>
    </>
  );
};

export default RepoFAB;
