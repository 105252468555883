import * as React from "react";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ChevronRight from "@mui/icons-material/ChevronRight";
import GavelIcon from "@mui/icons-material/Gavel";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import Button from "@mui/material/Button";
import HelpIcon from "@mui/icons-material/Help";
import InfoIcon from "@mui/icons-material/Info";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import { GlobalContext, IGlobalContext } from "../GlobalContextProvider";
import { useNavigate } from "react-router-dom";
import { q } from "../Tools";
import lfCfg from "../lf-config-frontend.json";
import { IconButton } from "@mui/material";

export interface DrawerMenuProps {
  open: boolean;
  toggleDrawerMenuHandler: (
    open: boolean
  ) => (event: React.KeyboardEvent | React.MouseEvent) => void;
}

interface IMenuItem {
  caption: string;
  href: string;
  icon: React.ReactNode;
  isShownWhenAuthenticated: boolean;
  isAuthenticationRequired: boolean;
  onClick?: () => void;
}

const DrawerMenu: React.FC<DrawerMenuProps> = ({
  open,
  toggleDrawerMenuHandler,
}) => {
  const globalContext: IGlobalContext = React.useContext(GlobalContext);
  const navigate = useNavigate();

  const logout = async () => {
    q(`logging out ...`);
    try {
      const result = await fetch(lfCfg.backend + "/_logout", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = await result.json();
      if (data.errorId === 0) {
        q(`logged out: ${JSON.stringify(data)}`);
        globalContext.dispatch({
          type: "SET_USER",
          value: null,
        });
        navigate("/");
      }
    } catch (e) {
      q(`error logging out: ${e}`);
    }
  };

  const menuItems: IMenuItem[] = [
    {
      caption: "Login",
      href: "/_login",
      icon: <LoginIcon />,
      isShownWhenAuthenticated: false,
      isAuthenticationRequired: false,
    },
    {
      caption: "My Linkbox",
      href: `/${globalContext.state.user?.username}`,
      icon: <HomeWorkIcon />,
      isShownWhenAuthenticated: true,
      isAuthenticationRequired: true,
    },
    {
      caption: "My Profile",
      href: "/_myprofile",
      icon: <AccountBoxIcon />,
      isShownWhenAuthenticated: true,
      isAuthenticationRequired: true,
    },
    {
      caption: "Terms",
      href: "/_terms",
      icon: <GavelIcon />,
      isShownWhenAuthenticated: true,
      isAuthenticationRequired: false,
    },
    {
      caption: "Imprint",
      href: "/_imprint",
      icon: <InfoIcon />,
      isShownWhenAuthenticated: true,
      isAuthenticationRequired: false,
    },
    {
      caption: "Logout",
      href: "/_logout",
      icon: <LogoutIcon />,
      isShownWhenAuthenticated: true,
      isAuthenticationRequired: true,
      onClick: logout,
    },
  ];

  const list = () => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawerMenuHandler(false)}
      onKeyDown={toggleDrawerMenuHandler(false)}
    >
      <div>
        <IconButton>
          <ChevronRight onClick={toggleDrawerMenuHandler(true)} />
        </IconButton>
      </div>
      <Divider />
      <List>
        {menuItems.map((menuItem, index) => {
          const listItem = (
            <ListItem key={index} disablePadding>
              {"onClick" in menuItem ? (
                <ListItemButton onClick={menuItem.onClick}>
                  <ListItemIcon>{menuItem.icon}</ListItemIcon>
                  <ListItemText primary={menuItem.caption} />
                </ListItemButton>
              ) : (
                <ListItemButton component={Link} href={menuItem.href}>
                  <ListItemIcon>{menuItem.icon}</ListItemIcon>
                  <ListItemText primary={menuItem.caption} />
                </ListItemButton>
              )}
            </ListItem>
          );

          if (!globalContext.state.user) {
            if (!menuItem.isAuthenticationRequired) return listItem;
          } else {
            if (
              menuItem.isShownWhenAuthenticated ||
              menuItem.isAuthenticationRequired
            )
              return listItem;
          }
        })}
      </List>
    </Box>
  );

  return (
    <div>
      <React.Fragment key={"right"}>
        <Drawer
          anchor={"right"}
          open={open}
          onClose={toggleDrawerMenuHandler(false)}
        >
          {list()}
        </Drawer>
      </React.Fragment>
    </div>
  );
};

export default DrawerMenu;
