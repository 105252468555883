import React from "react";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import { GlobalContext, IGlobalContext } from "../GlobalContextProvider";
import { q } from "../Tools";

const lfCfg = require("../lf-config-frontend.json");

interface IError {
  type: string;
  message?: string;
}

function Setup() {
  const [username, setUsername] = React.useState("");
  const [visibility, setVisibility] = React.useState<string>("public");
  const globalContext: IGlobalContext = React.useContext(GlobalContext);
  const [error, setError] = React.useState<IError | null>(null);

  const navigate = useNavigate();

  const handleChangeVisibility = () => {};

  const save = async () => {
    q(`saving ...`);
    try {
      const result = await fetch(lfCfg.backend + "/_setup", {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, visibility }),
      });

      const data = await result.json();
      if (data.errorId === 0) {
        q(`saved: ${JSON.stringify(data)}`);
        // take to home
        window.location.replace(lfCfg.frontend + "/" + username);
      } else {
        setError({
          type: "ERROR_USERNAME",
          message: data.message as unknown as string,
        });
      }
    } catch (e) {
      setError({
        type: "ERROR_USERNAME",
        message: e as unknown as string,
      });
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        marginTop: globalContext.state.isSmallMedia ? 18 : 12,
      }}
    >
      <Typography variant="h5">Welcome to Linkbox!</Typography>
      <Typography variant="body2" sx={{ marginTop: 4 }} textAlign="center">
        Thank you for signing up at Linkbox!
        <br /> As a next step, please pick your username:
      </Typography>
      <TextField
        sx={{ marginTop: 4 }}
        id="outlined-basic"
        label="Username"
        variant="outlined"
        value={username}
        helperText={
          error ? (
            <Typography variant="body2" color="red">
              {error.message}
            </Typography>
          ) : null
        }
        onChange={(e) => {
          setError(null);
          setUsername(new String(e.target.value).toLowerCase());
        }}
      />
      <Typography variant="body2" sx={{ marginTop: 4 }} textAlign="center">
        Your account will be at:
      </Typography>
      <Typography
        variant="body2"
        sx={{ marginTop: 2 }}
        textAlign="center"
        fontWeight="bold"
      >
        {`${lfCfg.frontend}/${globalContext.state.user?.username}`}
      </Typography>
      <Typography variant="body2" sx={{ marginTop: 2 }} textAlign="center">
        Please decide whether you want to have a public or private linkbox.{" "}
        <br />
        (You can change that later again, of course)
      </Typography>
      <Box sx={{ marginTop: 2 }}>
        <RadioGroup value={visibility} onChange={handleChangeVisibility}>
          <FormControlLabel
            value="public"
            control={<Radio />}
            checked={visibility === "public"}
            label={
              <Typography variant="body2">
                My linkbox will be visible to others (public)
              </Typography>
            }
            onClick={() => setVisibility("public")}
          />
          <FormControlLabel
            value="private"
            control={<Radio />}
            checked={visibility === "private"}
            label={
              <Typography variant="body2">
                My linkbox will not be visible to others (private)
              </Typography>
            }
            onClick={() => setVisibility("private")}
          />
        </RadioGroup>
      </Box>
      <Button sx={{ marginTop: 2 }} variant="contained" onClick={save}>
        Start using Linkbox
      </Button>
    </Box>
  );
}

export default Setup;
