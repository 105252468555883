import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { q } from "../../Tools";
import TextField from "@mui/material/TextField";
import YesNoDialog from "../YesNoDialog";
import Alert from "@mui/material/Alert";
const lfCfg = require("../../lf-config-frontend.json");

function Account() {
  const [resetPasswordEmailSent, setResetPasswordEmailSent] =
    React.useState<boolean>(false);
  const [showResetPasswordDialog, setShowResetPasswordDialog] =
    React.useState<boolean>(false);
  const [email, setEmail] = React.useState(null);

  const loadMyEmail = async () => {
    q(`loading my email ...`);
    try {
      const result = await fetch(lfCfg.backend + "/_myemail", {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = await result.json();
      if (data.errorId === 0) {
        q(`loaded my email: ${JSON.stringify(data)}`);
        setEmail(data.email);
      } else {
        q(`could not load my email`);
      }
    } catch (e) {
      q(`error loading email: ${e}`);
    }
  };

  const createForgotPasswordRequest = async () => {
    setShowResetPasswordDialog(false);
    try {
      const result = await fetch(lfCfg.backend + "/_forgotpassword", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
        }),
      });

      const data = await result.json();
      if (data.errorId === 0) {
        q(`forgot password request created: ${JSON.stringify(data)}`);
        setResetPasswordEmailSent(true);
        setTimeout(() => setResetPasswordEmailSent(false), 3000);
      } else {
        q(data.message);
      }
    } catch (e) {
      q(`error: ${e}`);
    }
  };

  React.useEffect(() => {
    loadMyEmail();
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: 450,
      }}
    >
      <Typography variant="h5">My Profile</Typography>
      <Box sx={{ marginTop: 4 }}>
        <TextField
          sx={{ marginTop: 2 }}
          InputProps={{
            inputProps: {
              style: { textAlign: "center" },
            },
          }}
          id="outlined-basic"
          value={email}
          variant="outlined"
          disabled
        />
      </Box>
      <Button
        onClick={() => setShowResetPasswordDialog(true)}
        sx={{ marginTop: 4 }}
      >
        Change Password
      </Button>
      {resetPasswordEmailSent && (
        <Alert severity="success" sx={{ marginTop: 4 }}>
          An email with instructions has been sent to {email}!
        </Alert>
      )}
      <YesNoDialog
        open={showResetPasswordDialog}
        onYes={createForgotPasswordRequest}
        onCancel={() => setShowResetPasswordDialog(false)}
        text="Do you want us to send you an email to reset your password?"
        title="Reset Password"
      />
    </Box>
  );
}

export default Account;
