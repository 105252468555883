import React from "react";
import Paper from "@mui/material/Paper";
import CloseIcon from "@mui/icons-material/Close";
import MinimizeIcon from "@mui/icons-material/Minimize";
import { GlobalContext, IGlobalContext } from "./GlobalContextProvider";

function Player() {
  const globalContext: IGlobalContext = React.useContext(GlobalContext);

  if (!globalContext.state.player) return null;

  const widthFAB = 2 * 16 + 2 * 56 + 16;

  return (
    <Paper
      elevation={3}
      style={{
        position: "fixed",
        bottom: 16,
        right: widthFAB,
        left: globalContext.state.isSmallMedia ? 8 : "auto",
        backgroundColor: "rgba(30, 30, 30)",
        width: globalContext.state.isSmallMedia
          ? `calc(100% - ${widthFAB}px`
          : globalContext.state.player.width,
        height: globalContext.state.player.minimized
          ? 56
          : globalContext.state.player.height,
        zIndex: 0,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: 10,
          color: "white",
        }}
      >
        <div>Linkbox Player</div>
        <div style={{ display: "flex" }}>
          <div
            style={{
              cursor: "pointer",
              marginTop: -3,
              marginLeft: 5,
            }}
            onClick={() =>
              globalContext.dispatch({
                type: "SET_PLAYER_MINIMIZED",
                value: !globalContext.state.player?.minimized,
              })
            }
          >
            <MinimizeIcon />
          </div>
          <div
            style={{
              textAlign: "right",
              cursor: "pointer",
              marginLeft: 5,
            }}
            onClick={() =>
              globalContext.dispatch({
                type: "SET_PLAYER",
                value: null,
              })
            }
          >
            <CloseIcon />
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          visibility: globalContext.state.player.minimized
            ? "hidden"
            : "visible",
        }}
        dangerouslySetInnerHTML={{ __html: globalContext.state.player.html }}
      ></div>
    </Paper>
  );
}

export default Player;
