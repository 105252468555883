import React from "react";
import { loadAuthStatus } from "../Auth";

const AuthSuccess: React.FC = () => {
  const _loadAuthStatus = async () => {
    const user = await loadAuthStatus();
    // a user who is logged in should be redirected to myprofile
    let url = null;
    if (user) {
      if (user.username === null) {
        url = "/_setup";
      } else {
        url = "/" + user.username;
      }

      window.location.href = url;
    }
  };

  React.useEffect(() => {
    _loadAuthStatus();
  }, []);

  return <div />;
};

export default AuthSuccess;
