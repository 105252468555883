import React, { createContext, Dispatch } from "react";

interface IPlayer {
  html: string;
  width: number;
  height: number;
  minimized: boolean;
}

export enum SearchResultType {
  Link = "link",
  Folder = "folder",
}

export interface ISearchResult {
  id: number;
  path: string;
  type: SearchResultType;
  foldertype: string;
  name: string;
  created: string;
  modified: string;
  owner: string;
  visibility: string;
  url: string;
  domain: string;
  domainname: string;
  embedcode: string;
  title: string;
}

export interface ISearch {
  searchQuery: string;
  searchResults: ISearchResult[];
}

export interface IPlayerSetting {
  id: string;
  domain: string;
  enabled: boolean;
}

export interface IUser {
  playerSettings: IPlayerSetting[];
  username: string;
}

export interface ILFState {
  appTitle: string;
  user: IUser | null;
  search: ISearch;
  resettingPassword: boolean;
  importingLinks: boolean;
  targetPath: string | null;
  moveError: string | null;
  player: IPlayer | null;
  isSmallMedia: boolean;
  headerHeight: number;
  rootToolsHeight: number;
  rootToolsHeightSuperSmallMedia: number;
  repositoryToolsHeightOwner: number;
  repositoryToolsHeightOwnerSuperSmallMedia: number;
  repositoryToolsHeightNotOwner: number;
  repositoryToolsHeightNotOwnerSuperSmallMedia: number;
  hamburgerMenuHeightLoggedIn: number;
  hamburgerMenuHeightLoggedOut: number;
}

export const initialState: ILFState = {
  appTitle: "Linkbox",
  user: null,
  search: {
    searchQuery: "",
    searchResults: [],
  },
  resettingPassword: false,
  importingLinks: false,
  targetPath: null,
  moveError: null,
  player: null,
  isSmallMedia: false,
  headerHeight: 76,
  rootToolsHeight: 36,
  rootToolsHeightSuperSmallMedia: 80,
  repositoryToolsHeightOwner: 80,
  repositoryToolsHeightOwnerSuperSmallMedia: 140,
  repositoryToolsHeightNotOwner: 40,
  repositoryToolsHeightNotOwnerSuperSmallMedia: 90,
  hamburgerMenuHeightLoggedIn: 202,
  hamburgerMenuHeightLoggedOut: 138,
};

type LFAction =
  | {
      type: "SET_SMALLMEDIA";
      value: boolean;
    }
  | {
      type: "SET_USER";
      value: IUser | null;
    }
  | {
      type: "SEARCH";
      value: ISearch;
    }
  | {
      type: "SET_PLAYER";
      value: IPlayer | null;
    }
  | {
      type: "SET_PLAYER_MINIMIZED";
      value: boolean;
    }
  | {
      type: "SET_RESETTING_PASSWORD";
      value: boolean;
    }
  | {
      type: "SET_TARGET_PATH";
      value: string;
    }
  | {
      type: "SET_MOVE_ERROR";
      value: string;
    }
  | {
      type: "SET_PLAYER_SETTINGS";
      value: IPlayerSetting[];
    }
  | {
      type: "SET_APP_TITLE";
      value: string;
    };

export interface IGlobalContext {
  state: ILFState;
  dispatch: Dispatch<LFAction>;
}

export const GlobalContext = createContext<IGlobalContext>({
  state: initialState,
  dispatch: () => null,
});

export const reducer = (state: ILFState, action: LFAction) => {
  switch (action.type) {
    case "SET_SMALLMEDIA":
      return { ...state, isSmallMedia: action.value };
    case "SET_USER":
      return { ...state, user: action.value };
    case "SEARCH":
      return { ...state, search: action.value };
    case "SET_PLAYER":
      return { ...state, player: action.value };
    case "SET_PLAYER_MINIMIZED":
      return {
        ...state,
        player: { ...state.player, minimized: action.value } as IPlayer,
      };
    case "SET_APP_TITLE":
      return { ...state, appTitle: action.value };
    case "SET_RESETTING_PASSWORD":
      return { ...state, resettingPassword: action.value };
    case "SET_PLAYER_SETTINGS":
      return {
        ...state,
        user: { ...state.user, playerSettings: action.value } as IUser,
      };

    default:
      return state;
  }
};

const GlobalContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return (
    <GlobalContext.Provider value={{ state, dispatch }}>
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalContextProvider;
