import React from "react";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { isValidEmail, IValidationResult } from "../Tools";
import { q } from "../Tools";
const lfCfg = require("../lf-config-frontend.json");

interface IError {
  type: string;
  message?: string;
}

enum ForgotPasswordStatus {
  EnteringEmail,
  EmailSent,
}

function ForgotPassword() {
  const [email, setEmail] = React.useState<string>("");
  const [error, setError] = React.useState<IError | null>(null);
  const [status, setStatus] = React.useState(
    ForgotPasswordStatus.EnteringEmail
  );

  const createForgotPasswordRequest = async () => {
    const validationResult: IValidationResult = isValidEmail(email);
    if (validationResult.errorId !== 0) {
      setError({ type: "ERROR", message: validationResult.message });
      return;
    }

    try {
      const result = await fetch(lfCfg.backend + "/_forgotpassword", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
        }),
      });

      const data = await result.json();
      if (data.errorId === 0) {
        q(`forgot password request created: ${JSON.stringify(data)}`);
        setStatus(ForgotPasswordStatus.EmailSent);
      } else {
        setError({
          type: "ERROR",
          message: data.message,
        });
      }
    } catch (e) {
      setError({
        type: "ERROR",
        message: e as string,
      });
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 10,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 10,
        }}
      >
        <Typography variant="h5">Forgot Password</Typography>
        {status === ForgotPasswordStatus.EnteringEmail && (
          <>
            <TextField
              sx={{ marginTop: 2 }}
              id="outlined-basic"
              label="Email"
              variant="outlined"
              value={email}
              onChange={(e) => {
                setError(null);
                setEmail(e.target.value);
              }}
            />
            <Button
              sx={{ marginTop: 2 }}
              variant="contained"
              onClick={createForgotPasswordRequest}
            >
              Send Reset Email
            </Button>
          </>
        )}
        {status === ForgotPasswordStatus.EmailSent && (
          <>
            <Typography variant="body2" sx={{ marginTop: 4 }}>
              An email with the reset email has been sent to {email}. <br />
              Please click the link inside the email, to reset your password.
            </Typography>
          </>
        )}
      </Box>
    </Box>
  );
}

export default ForgotPassword;
