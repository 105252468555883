import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import MenuIcon from "@mui/icons-material/Menu";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import FolderIcon from "@mui/icons-material/Folder";
import DrawerMenu from "./components/DrawerMenu";
import {
  GlobalContext,
  IGlobalContext,
  ISearchResult,
} from "./GlobalContextProvider";
import lfCfg from "./lf-config-frontend.json";
import { q } from "./Tools";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

export default function Header() {
  const [menuOpen, setMenuOpen] = React.useState<boolean>(false);
  const globalContext: IGlobalContext = React.useContext(GlobalContext);
  const [hoveredLinkIndex, setHoveredLinkIndex] = React.useState<number>(-1);
  const [searchQuery, setSearchQuery] = React.useState<string>("");
  const navigate = useNavigate();
  const location = useLocation();

  const toggleDrawerMenu =
    () => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setMenuOpen(!menuOpen);
    };

  const handleLogoClick = () => {
    navigate("/");
  };

  const createClickableAppTitle = (appTitle: string) => {
    if (appTitle === "Linkbox") return "Linkbox";

    const appTitleParts = appTitle.split("/");
    let linkArray = [];
    let fullPath = "";
    for (let i = 1; i < appTitleParts.length; i++) {
      fullPath += "/" + appTitleParts[i];
      const partialPath = fullPath;
      linkArray.push(
        <span>
          <span
            style={{
              marginLeft: i === 1 ? 10 : 0,
              color: "white",
              fontSize: 25,
            }}
          >
            /
          </span>
          <a
            className="titlelinks"
            key={i}
            onClick={() => navigate(partialPath)}
            onMouseOver={() => setHoveredLinkIndex(i)}
            onMouseOut={() => setHoveredLinkIndex(-1)}
            style={{
              fontSize: 25,
              textDecoration: hoveredLinkIndex === i ? "underline" : "none",
              color: "white",
              cursor: "pointer",
            }}
          >
            {appTitleParts[i]}
          </a>
        </span>
      );
    }

    return linkArray;
  };

  const search = async (query: string) => {
    if (query.length < 2) {
      return globalContext.dispatch({
        type: "SEARCH",
        value: {
          searchQuery: "",
          searchResults: [],
        },
      });
    }

    q(`searching for ${query} ...`);
    try {
      const fullPath = `${lfCfg.backend}${location.pathname}?search=${query}`;
      const result = await fetch(fullPath, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = await result.json();
      if (data.errorId === 0) {
        q(`loaded search results: ${JSON.stringify(data)}`);
        globalContext.dispatch({
          type: "SEARCH",
          value: {
            searchQuery: query,
            searchResults: data.searchResults as ISearchResult[],
          },
        });
      } else {
        q(`could not load search results`);
      }
    } catch (e) {
      q(`error loading search results: ${e}`);
    }
  };

  React.useEffect(() => {
    search(searchQuery);
  }, [searchQuery]);

  const handleSearchQueryChange = async (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const query = e.currentTarget.value;
    setSearchQuery(query);
  };

  return (
    <AppBar position="fixed">
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: globalContext.state.isSmallMedia ? "column" : "row",
          alignItems: globalContext.state.isSmallMedia
            ? "flex-start"
            : "center",
          paddingBottom: 1,
          paddingTop: 1,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton size="large" edge="start" color="inherit">
            <FolderIcon
              onClick={
                !globalContext.state.resettingPassword
                  ? handleLogoClick
                  : undefined
              }
            />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            <span style={{ paddingLeft: 8 }}>
              {createClickableAppTitle(globalContext.state.appTitle)}
            </span>
          </Typography>
        </Box>
        <Box
          style={{
            display: "flex",
            alignItems: globalContext.state.isSmallMedia
              ? "flex-start"
              : "center",
            width: globalContext.state.isSmallMedia ? "100%" : "auto",
          }}
        >
          {!location.pathname.startsWith("/_") && (
            <>
              <Search
                style={{
                  marginRight: 32,
                  width: globalContext.state.isSmallMedia ? "100%" : "auto",
                }}
              >
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search…"
                  inputProps={{ "aria-label": "search" }}
                  sx={{
                    width: globalContext.state.isSmallMedia ? "100%" : "auto",
                  }}
                  onChange={handleSearchQueryChange}
                  value={searchQuery}
                  endAdornment={
                    <IconButton
                      sx={{
                        visibility:
                          searchQuery.length > 0 ? "visible" : "hidden",
                      }}
                      onClick={() => setSearchQuery("")}
                    >
                      <ClearIcon color="primary" />
                    </IconButton>
                  }
                />
              </Search>
            </>
          )}
          {!location.pathname.startsWith("/_resetpassword") &&
            !location.pathname.startsWith("/_setup") &&
            !location.pathname.startsWith("/_activate") &&
            !location.pathname.startsWith("/_forgotpassword") &&
            !location.pathname.startsWith("/_resetpassword") &&
            !location.pathname.startsWith("/_authsuccess") && (
              <>
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="open drawer"
                  sx={
                    globalContext.state.isSmallMedia
                      ? {
                          position: "relative",
                          bottom: 6,
                        }
                      : {}
                  }
                >
                  <MenuIcon onClick={() => setMenuOpen(!menuOpen)} />
                </IconButton>
              </>
            )}
        </Box>
      </Toolbar>
      <DrawerMenu open={menuOpen} toggleDrawerMenuHandler={toggleDrawerMenu} />
    </AppBar>
  );
}
